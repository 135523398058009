<template>
  <!-- begin::Reports -->
  <div>
    <pre class="d-none">{{ Reports }}</pre>
    <div class="row">
      <!-- BI widget -->
      <div class="col-xxl-4 --d-none" v-for="(report, index) in Reports.StatsBI" :key="`statsbi-${index}`">
        <!-- begin:: Card -->
        <b-card
          text-variant="white"
          header-tag="header"
          header-class="border-0 py-5"
          footer-tag="footer"
          footer-class="border-0 py-5"
          bg-variant="warning"
          class="card-custom bg-warning gutter-b card-stretch"
        >
          <!-- begin:: Header slot -->
          <template #header>
            <h3 class="card-title font-weight-bolder text-white">
              <!-- Link fallback -->
              <a class="stretched-link text-white" :href="report.URL" target="_blank">
                {{ report.ReportName | safePrint }}
              </a>
            </h3>
            <div class="card-toolbar"></div>
          </template>
          <!-- end:: Header slot -->

          <!-- begin:: Card body -->
          <b-card-text class="d-flex flex-row align-items-center justify-content-center h-100">
            <span class="svg-icon svg-icon-9x mb-4 svg-icon-white mr-4">
              <inline-svg src="media/svg/icons/Design/Pixels.svg" />
            </span>
            <span class="ml-4 font-size-h6 text-start font-weight-bold" v-translate> A refreshed data visualization of your insights. </span>
          </b-card-text>
          <!-- end:: Card body -->

          <!-- begin:: Footer slot -->
          <template #footer>
            <b-button-group class="w-100">
              <!-- <a :href="report.URL" target="_blank" rel="noopener" variant="outline-white" v-translate>View</a> -->
              <b-button :href="report.URL" target="_blank" rel="noopener" variant="outline-white" v-translate>View</b-button>
            </b-button-group>
          </template>
          <!-- end:: Footer slot -->
        </b-card>
        <!-- end:: Card -->
      </div>

      <!-- Reports widget -->
      <div class="col-sm-6 col-md-3 col-lg-2" v-for="(report, index) in Reports.Stats" :key="`stats-${index}`">
        <!-- begin:: Card -->
        <b-card
          text-variant="opalean-gray-dark"
          body-class="py-2"
          header-tag="header"
          :header-class="
            report.StatType !== undefined
              ? `white border-0 py-5 min-h-120px bg-${getStatType(report.StatType, 'class')}`
              : 'border-0 py-5 min-h-120px bg-opalean-white opalean-gray-dark'
          "
          :header-bgcolor="getStatType(report.StatType, 'color')"
          footer-tag="footer"
          footer-class="border-0 py-5"
          class="card-custom gutter-b card-stretch"
        >
          <!-- begin:: Header slot -->
          <template #header>
            <h3 class="card-title font-weight-bolder text-white">
              <!-- Link fallback -->
              <a class="stretched-link text-white" @click="callReportModal(Reports.Stats[index], index)">
                {{ report.Label | safePrint }}
              </a>
            </h3>
            <div class="card-toolbar d-none"></div>
          </template>
          <!-- end:: Header slot -->

          <!-- begin:: Card body -->
          <b-card-text class="card-text d-flex flex-column align-items-center justify-content-center h-100">
            <!-- Type -->
            <span
              class="svg-icon svg-icon-8x mr-1 ml-n4"
              :class="report.StatType !== undefined ? `svg-icon-${getStatType(report.StatType, 'class')}` : 'svg-icon-light'"
            >
              <!--begin::Svg Icon-->
              <inline-svg :src="getStatType(report.StatType, 'icon')"></inline-svg>
              <!--end::Svg Icon-->
            </span>

            <span class="font-weight-bolder text-white font-size-sm ml-1 d-none" v-if="report.StatType !== undefined">{{
              $gettext(getStatType(report.StatType, "name")) | safePrint
            }}</span>
          </b-card-text>
          <!-- end:: Card body -->

          <!-- begin:: Footer slot -->
          <template #footer>
            <b-button-group class="w-100">
              <b-button
                :variant="report.StatType !== undefined ? `outline-${getStatType(report.StatType, 'class')}` : 'outline-opalean-gray-medium'"
                @click="callReportModal(Reports.Stats[index], index)"
              >
                <i class="flaticon2-download-2 icon-lg ml-2"></i> <translate>Get report</translate></b-button
              >
            </b-button-group>
          </template>
          <!-- end:: Footer slot -->
        </b-card>
        <!-- end:: Card -->
      </div>

      <!-- Partners account -->
      <div class="col-xxl-4 d-none">
        <!--begin::Partners account Widget-->
        <div class="card card-custom card-stretch gutter-b bg-info">
          <!--begin::Body-->
          <div class="card-body p-0 d-flex flex-column">
            <div class="d-flex align-items-start justify-content-between card-spacer flex-grow-1">
              <span class="symbol symbol-50 symbol-light-primary mr-2">
                <span class="symbol-label">
                  <i class="flaticon2-layers-2 icon-lg text-info"></i>
                </span>
              </span>
              <div class="d-flex flex-column text-right">
                <span class="text-white font-weight-bolder font-size-h3">+259</span>
                <span class="text-white font-weight-bold mt-2">Pallet account change</span>
              </div>
            </div>
            <!--begin::Chart-->
            <apexchart class="card-rounded-bottom" :options="chartOptions" :series="series" type="area" width="100%"></apexchart>
            <!--end::Chart-->
          </div>
          <!--end::Body-->
        </div>
        <!--end::Partners account Widget-->
      </div>
    </div>

    <!--begin::ReportModal -->
    <ReportModal :reportModal.sync="reportModal" v-if="reportModal.show"></ReportModal>
    <!--end::ReportModal -->
  </div>
  <!-- end::Reports -->
</template>

<style lang="scss">
.bg-yellow {
  background-color: var(--yellow) !important;
}
.bg-darkblue {
  background-color: #34495e !important;
}
.bg-darkgreen {
  background-color: #196859 !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import statics from "@/core/statics/statics.js";
import helpers from "@/core/statics/helpers.js";

import ReportModal from "@/view/content/neoopatrace/components/ReportModal.vue";

export default {
  title() {
    return this.$gettext("menu.reports");
  },
  name: "Reports",
  components: {
    ReportModal,
  },
  data() {
    return {
      // Statics
      statTypes: statics.statTypes,
      // Chart
      chartOptions: {},
      series: [
        {
          name: "Account",
          data: [40, 40, 30, 30, 35, 35, 50],
        },
      ],
      // Modal
      reportModal: {
        index: null,
        show: false,
      },
    };
  },
  computed: {
    ...mapGetters(["layoutConfig", "isAuthenticated", "getReports"]),
    // Get Reports data
    Reports: function () {
      return this.getReports;
    },
  },
  mixins: [helpers],
  methods: {
    // Modal
    callReportModal(data, index) {
      // Set props
      this.reportModal.index = index;
      this.reportModal.title = data.Label;
      this.reportModal.data = data;
      // Then, Show modal
      if (this.reportModal.show === false) this.reportModal.show = true;
    },
  },
  mounted() {
    console.info("Reports.vue");
    // Chart options
    this.chartOptions = {
      chart: {
        type: "area",
        height: "150",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "solid",
        opacity: 1,
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [this.layoutConfig("colors.theme.light.info")],
      },
      xaxis: {
        categories: ["Feb", "Mar", "Apr", "May", "Jun", "Aug", "Sep"],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.theme.inverse.info"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family"),
          },
        },
        crosshairs: {
          show: false,
          position: "front",
          stroke: {
            color: this.layoutConfig("colors.theme.inverse.gray-300"),
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        show: false,
        min: 0,
        max: 55,
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family"),
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: this.layoutConfig("font-family"),
        },
        y: {
          formatter: function (val) {
            return "approx. " + val + " pallets";
          },
        },
      },
      colors: [this.layoutConfig("colors.theme.hover.info")],
      markers: {
        colors: [this.layoutConfig("colors.theme.base.info")],
        strokeColor: [this.layoutConfig("colors.theme.base.light")],
        strokeWidth: 3,
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0,
        },
      },
    };
  },
  created() {
    if (this.isAuthenticated) {
      // Load Reports
      this.$store.dispatch("loadReports").then(() => {
        console.log("loadReports");
      });
    } else console.error("FATAL ERROR::Reports.vue isAuthenticated is false");
  },
};
</script>
