<template>
  <b-card text-variant="opalean-gray-dark" class="card-custom gutter-b w-100 h-100">
    <!-- Overlay de chargement -->
    <div class="spinner-overlay" v-if="isLoaded">
      <output class="spinner-border text-primary" aria-live="polite">
        <span class="sr-only">Loading...</span>
      </output>
    </div>

    <!-- Contenu principal de la carte -->
    <b-card-text class="d-flex flex-column align-items-center justify-content-center h-100">
      <!-- Zone de dépôt de fichiers -->
      <div
        class="drop-area"
        :class="{ 'drag-over': isDragOver }"
        @dragover.prevent="onDragOver"
        @dragenter.prevent="onDragEnter"
        @dragleave.prevent="onDragLeave"
        @drop.prevent="onDrop"
      >
        <!-- Icône de téléchargement -->
        <span class="svg-icon svg-icon-9x mb-8">
          <inline-svg src="media/svg/icons/Files/Cloud-upload.svg" />
        </span>

        <!-- Texte d'instruction -->
        <div class="mb-4">
          <span class="font-size-h6 text-start font-weight-bold" style="text-align: center" v-translate> Drag and drop to Upload File </span>
        </div>

        <!-- Bouton "Ou" -->
        <span class="font-size-h6 text-start font-weight-bold mb-6" style="text-align: center" v-translate>Or</span>

        <!-- Input de fichier caché -->
        <input type="file" ref="fileInput" class="d-none" accept=".xls,.xlsx" />

        <!-- Bouton "Parcourir" -->
        <b-button variant="outline-primary" class="btn btn-outline-primary font-weight-bolder font-size-h6 px-8 py-4 my-3" @click="browseFile" v-translate
          >Browse File</b-button
        >
      </div>
    </b-card-text>

    <!-- Pied de page de la carte -->
    <template v-slot:footer>
      <div class="d-flex justify-content-between align-items-center w-100">
        <!-- Informations sur les types de fichiers supportés -->
        <div class="d-flex flex-column align-items-start justify-content-start">
          <i class="font-size-h6 text-start font-weight-bold" style="text-align: center" v-translate> Supported file types: XLS, XLSX</i>
          <i class="font-size-h6 text-start font-weight-bold" style="text-align: center" v-translate> Supported file lines number: {{ maxFileLines }}</i>
          <i class="font-size-h6 text-start font-weight-bold" style="text-align: center">
            <translate>The date of the last file update must be less than</translate> {{ maxHoursFileAge }} <translate>hours</translate>
          </i>
        </div>

        <!-- Lien vers un exemple de fichier -->
        <div class="d-flex flex-column align-items-end justify-content-end">
          <div class="d-flex flex-row align-items-center text-primary">
            <i class="fas fa-file-alt mr-2 text-primary"></i>
            <a :href="exampleFileUrl" target="_blank" class="font-weight-bold" style="cursor: pointer" v-translate>{{ exampleFileLabel }}</a>
          </div>
        </div>
      </div>
    </template>
  </b-card>
</template>

<script>
import * as XLSX from "xlsx";

export default {
  data() {
    return {
      isDragOver: false,
      isLoaded: false,
      allowedFileTypes: ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
    };
  },

  props: {
    exampleFileLabel: {
      type: String,
      required: true,
    },
    exampleFileUrl: {
      type: String,
      required: true,
    },
    maxHoursFileAge: {
      type: Number,
      required: true,
    },
    maxFileLines: {
      type: Number,
      required: true,
    },
  },

  methods: {
    /**
     * Handles the 'dragover' event to indicate that the file can be dropped.
     * @param {Event} e - The dragover event.
     */
    onDragOver(e) {
      e.preventDefault();
      this.isDragOver = true;
    },

    /**
     * Handles the 'dragenter' event to indicate that the file has entered the drop area.
     * @param {Event} e - The dragenter event.
     */
    onDragEnter(e) {
      e.preventDefault();
      this.isDragOver = true;
    },

    /**
     * Handles the 'dragleave' event to remove the drag-over indication.
     * @param {Event} e - The dragleave event.
     */
    onDragLeave(e) {
      e.preventDefault();
      this.isDragOver = false;
    },

    /**
     * Handles the 'drop' event to process the dropped file(s).
     * This method prevents the default behavior, resets the drag-over state,
     * and processes the file(s) dropped if valid.
     * @param {Event} e - The drop event.
     */
    onDrop(e) {
      e.preventDefault();
      this.isDragOver = false;
      const files = e.dataTransfer.files;
      this.processFiles(files);
    },

    /**
     * Opens the file input dialog when the user clicks the 'Browse File' button.
     * Adds an event listener to watch for changes in the file input element.
     */
    browseFile() {
      const input = this.$refs.fileInput;
      input.click();
      const onChange = (e) => {
        const files = e.target.files;
        this.processFiles(files);
        input.removeEventListener("change", onChange);
      };
      input.addEventListener("change", onChange);
    },

    /**
     * Processes the provided file list. Validates and handles a single valid file.
     * Displays error messages for invalid cases or processes the file if valid.
     * @param {FileList} files - The list of files to process.
     */
    processFiles(files) {
      if (files.length === 0) {
        this.showFileValidationError(this.$gettext("No files were selected or dropped."));
        return;
      }

      if (files.length > 1) {
        this.showFileValidationError(this.$gettext("Multiple files are not supported. Please select or drop only one file."));
        return;
      }

      const file = files[0];
      if (file.size === 0) {
        this.showFileValidationError(this.$gettext("The file is empty. Please select a valid file."));
        return;
      }

      this.isLoaded = true;
      this.$nextTick(() => {
        this.validateFile(file);
      });
    },

    /**
     * Validates the selected file based on its last modified date, type, and size.
     * If any validation fails, shows an error message and stops further processing.
     * If all validations pass, processes the valid file.
     * @param {File} file - The file to validate.
     */
    async validateFile(file) {
      if (!this.isFileDateWithinAllowedRange(file)) {
        this.showFileValidationError(
          `${this.$gettext("Invalid file date. The date of the last file update must be less than")} ${this.maxHoursFileAge} ${this.$gettext("hours")}.`
        );
        return;
      }

      if (!this.isFileTypeSupported(file)) {
        this.showFileValidationError(this.$gettext("Invalid file type. Supported file types are XLS, XLSX."));
        return;
      }

      let fileContent = null;
      try {
        fileContent = await this.parseFileToCSV(file);
      } catch (error) {
        this.showFileValidationError(`${this.$gettext("Error while parsing")} ${file.name}: ${error.message}`);
        return;
      }

      if (!this.isNumberLinesWithinLimit(fileContent)) {
        this.showFileValidationError(this.$gettext(`Invalid number of lines. The maximum number of lines supported is ${this.maxFileLines}.`));
        return;
      }

      this.processFileAfterValidation(fileContent);
    },

    /**
     * Parses the uploaded file and converts it to CSV format.
     * @param {File} file - The file to be processed.
     * @returns {Promise}  A promise that resolves with the CSV content.
     * @function
     */
    async parseFileToCSV(file) {
      try {
        const reader = new FileReader();
        const data = await new Promise((resolve, reject) => {
          reader.onload = (e) => resolve(new Uint8Array(e.target.result));
          reader.onerror = (error) => reject(error);
          reader.readAsArrayBuffer(file);
        });

        // Configure options to preserve date format
        const options = {
          type: "array",
          cellDates: true,
          dateNF: "DD/MM/YYYY",
        };

        const workbook = XLSX.read(data, options);
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const fileContent = XLSX.utils.sheet_to_csv(worksheet, {
          header: 1,
          blankrows: false,
          rawNumbers: true,
          dateNF: "DD/MM/YYYY",
          cellDates: true, // Custom options to preserve cell formatting
          strip: false,
          FS: "¤",
          forceQuotes: true,
        });

        return fileContent;
      } catch (error) {
        this.showFileValidationError(`${this.$gettext("Error while parsing")} ${file.name}: ${error.message}`);
      }
    },

    /**
     * Displays an error toast message for an invalid file and resets the loading state.
     * @param {string} errorMessage - The error message to display in the toast notification.
     */
    showFileValidationError(errorMessage) {
      this.isLoaded = false;
      this.showToast("error", errorMessage);
    },

    /**
     * Checks if the file's last modified date is within the allowed range (less than the max allowed hours).
     * @param {File} file - The file to check.
     * @returns {boolean} - Returns true if the file date is within the allowed range, otherwise false.
     */
    isFileDateWithinAllowedRange(file) {
      const today = new Date();
      const fileDate = new Date(file.lastModified);
      const fileAgeInMilliseconds = today - fileDate;
      const numberOfMillisecondsInAnHour = 3_600_000;
      const dayFileAgeInMilliseconds = this.maxHoursFileAge * numberOfMillisecondsInAnHour;
      return fileAgeInMilliseconds < dayFileAgeInMilliseconds;
    },

    /**
     * Checks if the file type is one of the supported types (XLS, XLSX).
     * @param {File} file - The file to check.
     * @returns {boolean} - Returns true if the file type is supported, otherwise false.
     */
    isFileTypeSupported(file) {
      return this.allowedFileTypes.includes(file.type);
    },

    /**
     * Checks if the lines number is within the allowed range.
     * @param {File} fileContent - The content of the file to check.
     * @returns {boolean} - Returns true if the file size is within the limit, otherwise false.
     */
    isNumberLinesWithinLimit(fileContent) {
      const normalizedContent = fileContent.replace(/\r\n/g, "\n").replace(/\r/g, "\n");
      const lines = normalizedContent.split("\n").filter((line) => line.trim().length > 0); // Exclure les lignes vides

      // Exclure la première ligne (en-tête)
      return lines.length - 1 <= this.maxFileLines; // -1 pour l'en-tête
    },

    /**
     * Processes the valid file by emitting an event and resetting the loading state.
     * The processing delay is simulated based on the file size, with a minimum delay of 3000 ms.
     * @param {File} fileContent - The valid file to process.
     */
    processFileAfterValidation(fileContent) {
      this.$emit("fileDropped", fileContent);
      this.isLoaded = false;
    },
    /**
     * Displays a toast notification with the specified variant and message.
     * @param {string} variant - The variant of the toast (e.g., 'error').
     * @param {string} message - The message to display in the toast.
     */
    showToast(variant, message) {
      const Toast = Swal.mixin({
        toast: true,
        icon: variant,
        title: message,
        position: "top-end",
        showConfirmButton: false,
        timer: 4000,
      });
      Toast.fire();
    },
  },
};
</script>

<style>
.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

.drop-area {
  border: 2px dashed #ccc;
  padding: 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.drop-area .svg-icon {
  margin-bottom: 20px;
}

.drop-area div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.drop-area span {
  margin-bottom: 10px;
}

.drop-area b-button {
  margin-top: 10px;
}

.drop-area.drag-over {
  border-color: #007bff;
  background-color: rgba(0, 123, 255, 0.1);
}
</style>
