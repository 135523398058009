<template>
  <div>
    <section id="synced_map" class="position-relative" v-if="isGoogleMapsLoaded && partners.length > 0">
      <GmapMap
        ref="mapB"
        @zoom_changed="zoomMap($event, 'mapB')"
        @center_changed="centerMap($event, 'mapB')"
        @dragstart="dragMap('start', 'mapB')"
        @dragend="dragMap('end', 'mapB')"
        :center="mapCenter"
        :zoom="mapZoom"
        class="fh-in-content"
        :options="mapOptions"
      >
        <GmapCluster :zoomOnClick="true" :options="clusterOptions" @click="dragMap('click', 'mapB')">
          <GmapMarker
            v-for="partner in partners"
            :key="partner.id"
            :position="partner.position"
            :title="partner.title"
            :icon="createCustomMarker(partner)"
            :draggable="false"
            ><GmapInfoWindow :opened="false">
              <div :style="{ backgroundColor: getRoleStyle(partner.MainRole).backgroundColor, color: getRoleStyle(partner.MainRole).color, padding: '5px' }">
                {{ partner.title }}
              </div>
            </GmapInfoWindow></GmapMarker
          >
        </GmapCluster>
      </GmapMap>
    </section>
  </div>
</template>

<script>
import { components } from "gmap-vue";
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module.js";
import statics from "@/core/statics/statics.js";
import { mapGetters } from "vuex";

const CLUSTER_COLORS = {
  LOW: "#0000ff",
  HIGH: "#ff0000",
};

export default {
  name: "PartnersMaps",
  components: {
    GmapCluster: components.Cluster,
  },
  data() {
    return {
      dragging: { mapB: false },
      partners: [],
      isGoogleMapsLoaded: false,
    };
  },
  mounted() {
    this.$store.dispatch(ADD_BODY_CLASSNAME, "fitted-content");
    this.$gmapApiPromiseLazy().then(() => {
      this.isGoogleMapsLoaded = true;
      this.$nextTick(() => {
        this.initMap();
      });
    });
  },

  computed: {
    ...mapGetters(["getPartners"]),
    mapCenter: () => ({ lat: 46.52863469527167, lng: 2.43896484375 }),
    mapZoom: () => 7,
    mapOptions: () => statics.mapOptions,
    clusterOptions() {
      return {
        renderer: {
          render: this.renderCluster,
        },
      };
    },
  },
  watch: {
    getPartners: {
      immediate: true,
      handler() {
        this.loadPartners();
      },
    },
  },
  methods: {
    //#region mapMethods
    zoomMap(zoom, ref) {
      if (zoom !== this.$refs[ref].$mapObject.getZoom()) this.$refs[ref].$mapObject.setZoom(zoom);
    },
    centerMap(bounds, ref) {
      if (this.dragging[ref] === false && Object.values(this.dragging).some((x) => x === true)) {
        this.$refs[ref].$mapObject.setCenter(bounds);
      }
    },
    dragMap(e, ref) {
      this.dragging[ref] = e === "start";
      if (e === "click") {
        this.dragging[ref] = true;
        setTimeout(() => {
          this.dragging[ref] = false;
        }, 200);
      }
    },
    //#endregion
    renderCluster({ count, position }, stats) {
      const color = count > Math.max(10, stats.clusters.markers.mean) ? CLUSTER_COLORS.HIGH : CLUSTER_COLORS.LOW;
      const svg = this.createClusterSvg(color);
      return new window.google.maps.Marker({
        position,
        icon: {
          url: `data:image/svg+xml;base64,${window.btoa(svg)}`,
          scaledSize: new window.google.maps.Size(55, 55),
        },
        label: {
          text: String(count),
          color: "rgba(255,255,255,0.9)",
          fontSize: "10px",
          fontFamily: "Poppins, Helvetica, sans-serif",
          fontWeight: "bold",
        },
        title: `Cluster of ${count} partners`,
        zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
      });
    },
    createClusterSvg(color) {
      return `
        <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
          <circle cx="120" cy="120" opacity=".6" r="70" />
          <circle cx="120" cy="120" opacity=".3" r="90" />
          <circle cx="120" cy="120" opacity=".2" r="110" />
        </svg>`;
    },
    createCustomMarker(partner) {
      const roleStyles = {
        DE: { backgroundColor: "#EEE5FF", color: "#8950FC" },
        TR: { backgroundColor: "#FFE2E5", color: "#F64E60" },
        CH: { backgroundColor: "hsl(165, 74%, 88%)", color: "hsl(165, 76%, 44%)" },
        AD: { backgroundColor: "#fcecd0", color: "#FFC24F" },
      };

      const style = roleStyles[partner.MainRole] || { backgroundColor: "#FF0000", color: "#FFFFFF" };

      const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="10" fill="${style.backgroundColor}" stroke="${style.color}" stroke-width="2"/>
    </svg>
  `;

      return {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`,
        scaledSize: new window.google.maps.Size(24, 24),
        anchor: new window.google.maps.Point(12, 12),
      };
    },
    getRoleStyle(role) {
      const roleStyles = {
        DE: { backgroundColor: "#EEE5FF", color: "#8950FC" },
        TR: { backgroundColor: "#FFE2E5", color: "#F64E60" },
        CH: { backgroundColor: "hsl(165, 74%, 88%)", color: "hsl(165, 76%, 44%)" },
        AD: { backgroundColor: "#fcecd0", color: "#FFC24F" },
      };
      return roleStyles[role] || { backgroundColor: "#FF0000", color: "#FFFFFF" };
    },
    updateBounds(markers = this.partners, ref) {
      if (window.google && window.google.maps) {
        const bounds = new window.google.maps.LatLngBounds();
        markers.forEach((m) => bounds.extend(m.position));
        if (ref) this.$refs[ref].fitBounds(bounds);
        else this.$refs.mapB.fitBounds(bounds);
      }
    },
    loadPartners() {
      this.partners = this.getPartners
        .filter((partner) => partner.Longitude && partner.Latitude)
        .map((partner) => ({
          id: partner.PartnerID,
          title: partner.Name,
          position: { lat: parseFloat(partner.Latitude), lng: parseFloat(partner.Longitude) },
          MainRole: partner.MainRole,
        }));
      this.$nextTick(() => {
        if (this.partners.length > 0 && this.isGoogleMapsLoaded) {
          this.updateBounds();
        }
      });
    },

    initMap() {
      this.$refs.mapB.$mapPromise.then(() => {
        this.mapOptions.zoomControlOptions.position = window.google.maps.ControlPosition.TOP_LEFT;
        this.loadPartners();
      });
    },
  },
  beforeDestroy() {
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "fitted-content");
  },
};
</script>
