<template>
  <div>
    <!-- begin::Partners -->
    <div class="card card-custom --card-stretch gutter-b">
      <!--begin::Body-->
      <div class="card-body p-0">
        <!-- Selector -->
        <b-button
          v-if="getUserCapability('CanEditPartners')"
          to="/partners/create"
          size="lg"
          block
          variant="secondary"
          class="p-5 rounded-bottom-0 rounded-sm text-left font-weight-bold m-0"
          ><i class="flaticon2-plus"></i> <translate>Create</translate></b-button
        >
        <!-- Filters -->
        <v-app class="rounded-top-sm d-none d-sm-block">
          <b-button-group class="m-0 btn-block bg-opalean-gray-light rounded-top-0 rounded-sm">
            <b-button
              v-b-toggle.list-filters
              size="lg"
              block
              variant="opalean-gray-light"
              class="btn p-5 rounded-top-0 rounded-sm text-left font-weight-bold m-0 btn-opalean-gray-light collapsed"
              ><i class="flaticon2-dashboard"></i> <translate>Filters</translate>
              <span v-if="!haveAnyFilters()" class="float-right"><i class="la la-filter"></i></span>
            </b-button>
            <!--
            <b-dropdown
              squared
              variant="opalean-gray-light"
              class="w-10 --border-left --border-opalean-gray-medium"
              toggle-class="rounded-top-0 btn-hover-bg-opalean-gray-medium btn-hover-text-dark btn-hover-icon-dark"
              no-caret
              right
            >
              <template #button-content>
                <i class="la la-ellipsis-v icon-xl"></i>
              </template>

              <b-dropdown-header id="dropdown-header-1" v-translate>Export</b-dropdown-header>
              <b-dropdown-item>
                <vue-excel-xlsx
                  :data="this.excelFilteredItems"
                  :columns="columnsExcel"
                  :fileName="`${$gettext('Partner')}_${getNow()}`"
                  :sheetName="$gettext('Partner sheet')"
                  class="stretched-link"
                >
                  <translate>Excel (*.xls)</translate>
                </vue-excel-xlsx>
              </b-dropdown-item>
              </!-- <b-dropdown-item>
                <translate>Print (*.pdf)</translate>
                <span class="ml-2 label label-outline-info label-pill label-inline" v-translate>Coming soon</span>
              </b-dropdown-item> --/>
            </b-dropdown>-->
            <b-button
              squared
              variant="opalean-gray-light "
              class="text-hover-opalean-dark flaticon-map-location icon-xxl m-0"
              style="-webkit-text-stroke: 0.5px currentColor"
              :to="{ name: 'route.partners.maps' }"
            >
            </b-button>
            <b-button
              v-if="getUserCapability('CanDragPartners')"
              squared
              size="lg"
              variant="opalean-gray-light text-hover-opalean-dark"
              class="font-weight-bold m-0"
              :to="{ name: 'route.partners.import' }"
              ><translate>Import your partners</translate>
            </b-button>
          </b-button-group>

          <b-collapse id="list-filters" class="mt-0">
            <b-card class="border-top-0 rounded-top-0 rounded-sm" body-class="px-5 bg-opalean-whiter">
              <p class="card-text font-size-lg font-weight-bold" v-translate>Filters</p>
              <div class="mt-2 row d-flex">
                <div class="col-12 col-sm-3 mr-2 mb-2">
                  <b-form-group
                    :label="$gettext('Search')"
                    label-class="text-dark font-size-xs font-weight-bolder text-uppercase"
                    :description="$gettext('Examples : Auchan, Grimonprez, Grim, 0003, AF, Opalean, Dusseldofer, 11/12...')"
                    label-for="filter-input"
                  >
                    <b-input-group size="lg" class="pt-3">
                      <b-form-input id="filter-input" v-model="search" type="search" :placeholder="$gettext('Type to search')"></b-form-input>

                      <b-input-group-append>
                        <b-button @click="search = ''" v-translate>Clear</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>

                <FormFields v-for="key in fieldKeys" :key="key" :selectVal="selectVal" :fieldKey="key" :options="listOptions[key]"></FormFields>

                <b-col lg="12">
                  <b-button
                    block
                    variant="outline-opalean-gray-medium"
                    size="lg"
                    class="btn-hover-danger font-weight-bold"
                    v-if="!haveAnyFilters()"
                    :disabled="haveAnyFilters()"
                    @click="clearAllFilters($event)"
                    ><i class="flaticon2-refresh-button icon-md"></i> <translate>Clear all</translate></b-button
                  >
                </b-col>
              </div>
            </b-card>
          </b-collapse>
        </v-app>

        <!--begin::Table-->
        <v-app class="rounded-bottom-sm">
          <v-main>
            <v-data-table
              id="partnersTable"
              :headers="!$vuetify.breakpoint.xs ? headers : mobileHeaders"
              :items="Partners"
              :page.sync="page"
              :items-per-page.sync="perPage"
              :footer-props="{
                'items-per-page-options': perPageChoices,
              }"
              hide-default-footer
              :search="search"
              :item-class="rowClass"
              item-key="PartnerID"
              sort-by="PartnerID"
              class="table table-vertical-center table-responsive-sm bg-opalean-white mb-0"
              ref="tableItems"
              @current-items="onFiltered"
            >
              <template v-slot:[`item.MainRole`]="{ item }" class="m-0">
                <span
                  v-for="(role, index) in getMainRoleTypes(item.MainRole)"
                  :key="index"
                  class="symbol symbol-40 mr-3"
                  :class="[`symbol-light-${role.class}`, item.Attributes.includes('isActive') ? null : 'symbol-muted']"
                  :title="showPopovers ? `Partner role` : null"
                  v-b-popover.hover.html="showPopovers ? `<h4 class='font-weight-bolder text-${role.class}'>${role.name}</h4> ${role.description}` : null"
                >
                  <!--:title="`The partner role is ${role.name}`" -->
                  <!--v-b-popover.hover.html="
                    `<h3>${role.id}</h3> <span class='font-weight-bolder'>${role.name}</span><br/> ${role.description}`
                  "-->
                  <span class="symbol-label font-size-h5 font-weight-bolder">{{ $mainFunctions.getFirstLetters(item.Name) }}</span>
                </span>
              </template>

              <template v-slot:[`item.Name`]="{ item }">
                <DetailsPanel
                  v-if="item.Name !== null && item.Name !== ''"
                  :inlineIconSrc="item.Attributes.includes('isClient') ? 'flaticon2-correct' : null"
                  :title="item.Name"
                  :titleMuted="item.City"
                  :inverted="true"
                >
                </DetailsPanel>
                <span v-else class="text-muted">—</span>
              </template>

              <template v-slot:[`item.ZipCode`]="{ item }">
                <span class="font-size-lg" v-if="item.ZipCode !== null && item.ZipCode !== ''">{{ item.ZipCode }}</span>
                <span v-else class="text-muted">—</span>
              </template>

              <template v-slot:[`item.City`]="{ item }">
                <span class="font-size-lg" v-if="item.City !== null && item.City !== ''">{{ item.City }}</span>
                <span v-else class="text-muted">—</span>
              </template>

              <template v-slot:[`item.CountryISO`]="{ item }">
                <span class="font-size-md font-weight-bold" v-if="item.CountryISO !== null && item.CountryISO !== ''">
                  <i class="fas fa-globe icon-sm"></i>
                  {{ item.CountryISO | safePrint }}</span
                >
                <span v-else class="text-muted">—</span>
              </template>
              <!-- @Victor a voir si pas une meilleure solution -->
              <template v-slot:[`item.isClient`]="{}"> </template>
              <template v-slot:[`item.isActive`]="{ item }">
                <span
                  class="label label-inline label-lg label-light-success font-weight-bolder font-size-lg w-100"
                  :class="item.Attributes.includes('isActive') ? 'label-light-success' : 'label-light-danger'"
                >
                  {{ item.Attributes.includes("isActive") ? $gettext("Active") : $gettext("Unactive") }}
                </span>
              </template>

              <template v-slot:[`item.`]="{ item }">
                <div class="d-flex align-items-center justify-content-center">
                  <!-- Edit button -->
                  <div class="--p-3">
                    <b-button
                      v-if="getUserCapability('CanEditPartners')"
                      :to="{
                        name: 'route.partners.edit',
                        params: {
                          PartnerID: item.PartnerID,
                          //GlobalID: item.GlobalID
                        },
                      }"
                      variant="hover-light-bg-secondary"
                      class="btn-hover-text-dark btn-hover-icon-dark"
                      v-b-tooltip.hover
                      :title="$gettext('Edit partner')"
                      ><i class="la la-edit icon-xl"></i
                    ></b-button>
                  </div>

                  <!-- Actions dropdown -->
                  <b-dropdown variant="link" class="--p-3" toggle-class="text-decoration-none btn-hover-text-white btn-hover-icon-dark" no-caret>
                    <template #button-content>
                      <i class="la la-ellipsis-h icon-xl"></i>
                    </template>

                    <b-dropdown-header id="dropdown-header-1" v-translate>View</b-dropdown-header>

                    <li>
                      <router-link
                        custom
                        class="dropdown-item"
                        role="menuitem"
                        :to="{ name: 'route.operations.list', params: { PartnerID: item.PartnerID, runCallBySearch: true } }"
                        ><translate>Searched in operations</translate>
                      </router-link>
                    </li>

                    <!-- Route params not working <b-dropdown-item
                      :to="{
                        name: 'route.operations.list',
                        params: { runCallBySearch: true, PartnerID: item.PartnerID },
                      }"
                      v-translate
                    >
                      Searched in operations
                    </b-dropdown-item>-->

                    <!-- <b-dropdown-item
                      :to="{
                        name: 'route.operations.list',
                        params: {
                          PartnerName: item.Name, // TEMP : En attente des JSON / WARNING
                        },
                      }"
                      v-translate
                    >
                      In operations
                    </b-dropdown-item> -->

                    <b-dropdown-item
                      :to="{
                        name: 'route.accounts',
                        params: {
                          PartnerName: item.Name, // TEMP : En attente des JSON
                        },
                      }"
                    >
                      <translate>In accounts</translate>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>

              <!-- Footer -->
              <template v-slot:footer class="border-top">
                <v-row class="px-3 py-2 align-center">
                  <v-col
                    class="d-flex flex-column justify-content-center --align-items-center border-right border-bottom-0 border-opalean-gray-light text-dark-75"
                    v-if="filteredItemsLength !== itemsLength"
                  >
                    <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Filtered</label>
                    <span class="font-weight-bolder">{{ filteredItemsLength }} <translate>partners</translate></span>
                  </v-col>

                  <v-col
                    class="d-flex flex-column justify-content-center --align-items-center border-right border-bottom-0 border-opalean-gray-light text-dark-75"
                  >
                    <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Total</label>
                    <span class="font-weight-bolder">{{ itemsLength }} <translate>partners</translate></span>
                  </v-col>

                  <v-col
                    class="d-flex flex-column justify-content-center --align-items-center border-right border-bottom-0 border-opalean-gray-light text-dark-75"
                  >
                    <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Showing</label>
                    <v-select dense outlined hide-details :value="perPage" @change="perPage = parseInt($event, 10)" :items="perPageChoices"> </v-select>
                  </v-col>

                  <v-col
                    class="d-flex flex-column justify-content-center --align-items-center border-right-0 border-bottom-0 border-opalean-gray-light text-dark-75"
                  >
                    <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Page</label>
                    <v-pagination v-model="page" :length="pageCount" class="pagination-start"></v-pagination>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-main>
        </v-app>
      </div>
    </div>
  </div>
  <!-- end:: Accounts -->
</template>

<script>
// import * as moment from "moment";

import { mapGetters } from "vuex";
//import ApiService from "@/core/services/api.service";

import FormFields from "@/view/content/neoopatrace/components/FormFields.vue";
import DetailsPanel from "@/view/content/neoopatrace/components/DetailsPanel.vue";

// import VueExcelXlsx from "vue-excel-xlsx";

import statics from "@/core/statics/statics.js";
import helpers from "@/core/statics/helpers.js";

// Vue.use(VueExcelXlsx);

export default {
  title() {
    return this.$gettext("menu.partners");
  },
  components: {
    FormFields,
    DetailsPanel,
  },
  name: "listPartners",
  data() {
    return {
      // Preferences
      showPopovers: statics.showPopovers,
      // // Excel
      // columnsExcel: [
      //   {
      //     label: this.$gettext("Name"),
      //     field: "Name",
      //   },
      //   {
      //     label: this.$gettext("Zip code"),
      //     field: "ZipCode",
      //   },
      //   {
      //     label: this.$gettext("City"),
      //     field: "City",
      //   },
      //   {
      //     label: this.$gettext("Country"),
      //     field: "CountryISO",
      //   },
      //   // These fields are not directly defined, so unreachable
      //   // {
      //   //   label: this.$gettext("Activity"),
      //   //   field: "isActive",
      //   // },
      //   // {
      //   //   label: this.$gettext("Client"),
      //   //   value: "isClient",
      //   // },
      //   // We have to process this field
      //   {
      //     label: this.$gettext("Attributes"),
      //     field: "Attributes",
      //     dataFormat: this.excelAttributesFormat,
      //   },
      // ],
      // Pagination
      page: 1,
      perPage: 50,
      perPageChoices: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "500", value: 500 },
      ],
      filteredItemsLength: 0,
      // Table group & filters
      search: "",
      //Table
      headers: [
        {
          text: this.$gettext("Role"),
          value: "MainRole",
          filter: (value) => {
            // console.log("MainRole##", value);
            // console.log("MainRole==", this.selectVal.MainRole.value);
            if (!this.selectVal.MainRole.value) return true;
            return value == this.selectVal.MainRole.value;
          },
          width: "1%",
          size: "100%",
        },
        { text: this.$gettext("Name"), value: "Name" },
        {
          text: "_Name", // For search filter purpose
          value: "_Name",
          width: "0",
          sortable: false,
          class: "d-none",
          cellClass: "d-none",
        },
        {
          text: "_Reference", // For search filter purpose
          value: "_Reference",
          width: "0",
          sortable: false,
          class: "d-none",
          cellClass: "d-none",
        },
        {
          text: "Reference", // For search filter purpose
          value: "Reference",
          width: "0",
          sortable: false,
          class: "d-none",
          cellClass: "d-none",
        },
        {
          text: this.$gettext("Zip code"),
          value: "ZipCode",
          width: "15%",
        },
        {
          text: this.$gettext("City"),
          value: "City",
          filter: (value) => {
            if (!this.selectVal.selectCityFilter.value) return true;
            if (_.lowerCase(_.deburr(value.toString())).includes(_.lowerCase(_.deburr(this.selectVal.selectCityFilter.value))) == true) return value;
          },
        },
        {
          text: this.$gettext("Country"),
          value: "CountryISO",
          filter: (value) => {
            // console.log("CountryISO##", value);
            // console.log("CountryISO==", this.selectVal.CountryISO.value);
            if (!this.selectVal.CountryISO.value) return true;
            return value == this.selectVal.CountryISO.value;
          },
          width: "8%",
        },
        {
          text: "_isClient",
          value: "isClient",
          filter: (value, search, item) => {
            // console.log("isClient##item", item.Attributes.includes("isClient"));
            // console.log("isClient==selectValvalue", this.selectVal.isClient.value);
            if (this.selectVal.isClient.value == undefined) return true;
            return item.Attributes.includes("isClient") == this.selectVal.isClient.value;
          },
          width: "0",
          sortable: false,
          class: "d-none",
          cellClass: "d-none",
        },
        {
          text: "District", // For search filter purpose
          value: "District",
          width: "0",
          sortable: false,
          class: "d-none",
          cellClass: "d-none",
        },
        {
          text: this.$gettext("Activity"),
          value: "isActive",
          filter: (value, search, item) => {
            // console.log("isActive##item", item.Attributes.includes("isActive"));
            // console.log("isActive==selectValvalue", this.selectVal.isActive.value);
            if (this.selectVal.isActive.value == undefined) return true;
            return item.Attributes.includes("isActive") == this.selectVal.isActive.value;
          },
          width: "10%",
        },
        {
          text: this.$gettext("Actions"),
          value: "",
          width: "9%",
          sortable: false,
        },
      ],
      mobileHeaders: [
        {
          text: this.$gettext("Role"),
          value: "MainRole",
          filter: (value) => {
            // console.log("MainRole##", value);
            // console.log("MainRole==", this.selectVal.MainRole.value);
            if (!this.selectVal.MainRole.value) return true;
            return value == this.selectVal.MainRole.value;
          },
          width: "1%",
          size: "100%",
        },
        { text: this.$gettext("Name"), value: "Name" },
        {
          text: this.$gettext("Zip code"),
          value: "ZipCode",
          width: "15%",
        },
        {
          text: this.$gettext("City"),
          value: "City",
          filter: (value) => {
            if (!this.selectVal.selectCityFilter.value) return true;
            if (value.toString().includes(this.selectVal.selectCityFilter.value) == true) return value;
          },
        },
        {
          text: this.$gettext("Country"),
          value: "CountryISO",
          filter: (value) => {
            // console.log("CountryISO##", value);
            // console.log("CountryISO==", this.selectVal.CountryISO.value);
            if (!this.selectVal.CountryISO.value) return true;
            return value == this.selectVal.CountryISO.value;
          },
          width: "8%",
        },
        {
          text: this.$gettext("Activity"),
          value: "isActive",
          filter: (value, search, item) => {
            // console.log("isActive##item", item.Attributes.includes("isActive"));
            // console.log("isActive==selectValvalue", this.selectVal.isActive.value);
            if (this.selectVal.isActive.value == undefined) return true;
            return item.Attributes.includes("isActive") == this.selectVal.isActive.value;
          },
          width: "10%",
        },
        {
          text: this.$gettext("Actions"),
          value: "",
          width: "9%",
          sortable: false,
        },
      ],
      selectVal: {
        MainRole: {
          value: undefined,
          type: "select",
          name: this.$gettext("Role"),
          label: "name", // optional
        },
        CountryISO: {
          value: undefined,
          type: "select",
          name: this.$gettext("Country"),
          key: "ISO2", // optional
          label: "Name", // optional
        },
        selectCityFilter: {
          value: undefined,
          type: "input",
          name: this.$gettext("City"),
          description: this.$gettext("Filter entire or partial cities name : Paris, Lille, Marsei, Nan,..."),
        },
        isClient: {
          value: undefined,
          type: "boolean",
          name: this.$gettext("Linked"),
          description: this.$gettext("Filter linked client or not"),
          label: "name", // optional
        },
        isActive: {
          value: undefined,
          type: "boolean",
          name: this.$gettext("Activated"),
          description: this.$gettext("Filter active client or not"),
          label: "name", // optional
        },
      },
      // Partners: [], //VICTOR: imported with mock API
      // CountryISO: [], //VICTOR: Imported with mock API // ATTENTION > Erreur victor > eviter CountryISOs > CountryISO
      MainRole: statics.partnerMainRoleTypes, //VICTOR: Imported with statics // ATTENTION > Erreur victor > eviter CountryISOs > CountryISO
      // Parent / child
      hasFormFields: true, // Helper to retrieve $aprent inside the FormFields.vue component
    };
  },
  mixins: [helpers],
  methods: {
    rowClass(item) {
      return item.Active === false ? "muted" : undefined;
    },
    haveAnyFilters() {
      return (this.search == "" || this.search == undefined) && Object.keys(this.selectVal).every((k) => this.selectVal[k].value == undefined) ? true : false;
    },
    clearAllFilters(e) {
      // Prevent default
      e.preventDefault();
      // Needs to debounce because it's happening right at the same time from unblur
      setTimeout(() => {
        this.search = "";
        Object.keys(this.selectVal).forEach((key) => {
          this.selectVal[key].value = undefined;
        });
      }, 100);
    },
    // Filtering
    onFiltered(filteredItems) {
      console.log(
        "onFiltered::",
        filteredItems,
        this.haveAnyFilters(),
        typeof this.$refs.tableItems !== "undefined" ? this.$refs.tableItems.$children[0].filteredItems.length : undefined
      );
      // Store updated items length
      // Only if we look for a filter ( not perPage filter )
      if (!this.haveAnyFilters())
        this.filteredItemsLength = this.$refs.tableItems !== undefined ? this.$refs.tableItems.$children[0].filteredItems.length : filteredItems.length;
      else this.filteredItemsLength = this.itemsLength;

      // Then update excel filters
      // this.setExcelFilter(filteredItems);
    },
    // setExcelFilter() {
    //   if (this.$refs.tableItems !== undefined) this.excelFilteredItems = this.$refs.tableItems.$children[0].filteredItems;
    //   else this.excelFilteredItems = this.Partners;
    // },
    // excelAttributesFormat(data) {
    //   return "[ " + data.join(", ") + " ]";
    // },
    // getNow() {
    //   return moment().format("DDMMYYYY_h-mm");
    // },
  },
  computed: {
    ...mapGetters(["getPartnersFor", "getCountries", "getUserCapability"]),
    // Data
    Partners() {
      return this.getPartnersFor("OPS");
    },
    CountryISO() {
      return this.getCountries;
    },
    // Pagination
    itemsLength() {
      return this.Partners.length ?? 0;
    },
    pageCount() {
      console.log("pageCount::", this.filteredItemsLength, this.itemsLength, Math.ceil(this.filteredItemsLength / this.perPage) ?? 1);
      return Math.ceil(this.filteredItemsLength / this.perPage) ?? 1;
    },
    // Options
    fieldKeys() {
      return Object.keys(this.selectVal);
    },
    listOptions() {
      // This could be simplified if the select was it's own component.
      const listOptions = {};
      this.fieldKeys.forEach((key) => {
        if (this.selectVal[key].type == "select") {
          const vals = this.Partners.filter((item) => item[key] !== "").map((item) => item[key]); // Added to avoid MainRole empty key role
          console.log("listOptions::key", vals);
          // Cheap and efficient unique.
          listOptions[key] = Array.from(new Set(vals));
        }
      });

      // Sort options
      listOptions.CountryISO.sort();
      listOptions.MainRole.sort();

      console.log("listOptions::", listOptions);
      return listOptions;
    },
  },
  mounted() {
    console.info("ListPartner.vue");
    // Set filtered items length
    this.filteredItemsLength = this.Partners.length;
  },
  // https://jerickson.net/created-vs-mounted-in-vue/
  created() {
    // Reload
    // Load Partners
    this.$store.dispatch("loadPartners").then(() => {
      console.log("loadPartners");
    });
  },
  watch: {
    // Add data & labels for filters ( NDW : see it's not to busy to load )
    Partners: {
      //deep: true, // Detect nested value changes inside Objects
      immediate: true, // Initiate at first load, trigger the callback immediately with the current value of the expression
      handler: function (newItems) {
        console.log("Watch::Partners length=" + newItems.length, this.Partners);
        // Watch and store Operations list IDs and Filters to store
        this.Partners.forEach((p) => {
          if (typeof p.Name !== "undefined") p._Name = _.lowerCase(_.deburr(p.Name)); // Deburrs string by converting Latin-1 Supplement and Latin Extended-A letters to basic Latin letters and removing combining diacritical marks.
          if (typeof p.Reference !== "undefined") p._Reference = _.lowerCase(_.deburr(p.Reference)); // Deburrs string by converting Latin-1 Supplement and Latin Extended-A letters to basic Latin letters and removing combining diacritical marks.
        });
      },
    },
  },
};
</script>
