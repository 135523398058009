<template>
  <div :class="groupClass">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <label class="font-weight-bold mb-0" :for="inputId">
        <translate>{{ label }}</translate>
        <span v-if="required" class="required">*</span>
      </label>
      <b-button v-if="showCopyButton" variant="outline-success" size="sm" @click="copyToClipboard" class="copy-button">
        <i class="fa-regular fa-copy"></i>
      </b-button>
    </div>
    <b-form-input
      :id="inputId"
      :value="value"
      @input="$emit('input', $event)"
      debounce="0"
      :placeholder="$gettext(placeholder)"
      size="lg"
      :class="inputClass"
      :style="inputStyle"
    ></b-form-input>
  </div>
</template>

<script>
export default {
  name: "FormGroupInput",
  props: {
    value: [String, Number],
    label: String,
    inputId: String,
    placeholder: String,
    groupClass: {
      type: String,
      default: "form-group mb-0",
    },
    inputStyle: {
      type: Object,
      default: () => ({}),
    },
    inputClass: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    showCopyButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCopied: false,
    };
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.value).then(() => {
        this.isCopied = true;
        setTimeout(() => {
          this.isCopied = false;
        }, 300);
      });
    },
  },
};
</script>
