<template>
  <!--begin::Balance Widget -->
  <div class="card card-custom gutter-b card-stretch">
    <!--begin::Header-->
    <div class="card-header border-0 py-5 bg-opalean-white">
      <h3 class="card-title align-items-start flex-column my-1">
        <span class="card-label font-weight-bolder text-dark" v-translate> Balance </span>
        <span class="text-muted mt-2 font-weight-bold font-size-sm" v-translate>Accounts</span>
      </h3>
      <div class="card-toolbar">
        <a
          class="btn btn-transparent btn-hover-info text-hover-white p-2 toggle"
          v-if="expandable"
          @click="
            parameters !== null && typeof parameters.Expanded !== 'undefined' && parameters.Expanded !== null
              ? (parameters.Expanded = !parameters.Expanded)
              : $set(parameters, 'Expanded', false)
          "
          ><span
            class="svg-icon m-0"
            :class="parameters !== null && typeof parameters.Expanded !== 'undefined' && parameters.Expanded ? 'svg-icon-info' : 'svg-icon-opalean-gray-medium'"
            ><inline-svg src="media/svg/icons/Layout/Layout-left-panel-1.svg"></inline-svg
          ></span>
        </a>
        <a class="btn btn-transparent btn-hover-info text-hover-white p-2 handle"
          ><span class="svg-icon svg-icon-opalean-gray-medium m-0"><inline-svg src="media/svg/icons/Layout/Layout-4-blocks.svg"></inline-svg></span>
        </a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body bg-white">
      <!--begin::Balance datas-->

      <!-- Balance row -->
      <div class="row">
        <div :class="parameters !== null && typeof parameters.Expanded !== 'undefined' && parameters.Expanded ? 'col-lg-6' : 'col-lg-12'">
          <span class="card-title font-weight-bolder text-opalean-gray-medium font-size-h6 mb-2 d-block">{{
            (BalanceWidget.Credit - BalanceWidget.Debt) | pluralize($gettext("Pallet"), $gettext("Pallets"))
          }}</span>
          <div
            class="font-weight-medium --font-size-h1 display2 d-flex align-items-center w-100"
            :class="BalanceWidget.Credit - BalanceWidget.Debt > 0 ? 'text-opalean-info' : 'text-info'"
          >
            <span class="svg-icon svg-icon-4x ml-n2 mr-2" :class="BalanceWidget.Credit - BalanceWidget.Debt >= 0 ? 'svg-icon-opalean-info' : 'svg-icon-info'">
              <inline-svg src="media/svg/icons/Design/Layers.svg" />
            </span>
            <b-skeleton-wrapper :loading="typeof BalanceWidget.Credit === 'undefined' || BalanceWidget.Credit === undefined" class="mt-2 w-80">
              <template #loading>
                <b-skeleton animation="fade"></b-skeleton>
              </template>
              {{ (BalanceWidget.Credit - BalanceWidget.Debt) | getNumberFormat }}
            </b-skeleton-wrapper>
          </div>

          <div
            class="text-opalean-gray-medium font-weight-bolder font-size-h2 ml-2 d-flex align-items-center justify-content-between"
            v-if="
              (BalanceWidget.ObjectiveIn !== 0 && typeof BalanceWidget.ObjectiveIn !== 'undefined') ||
              (BalanceWidget.ObjectiveOut !== 0 && typeof BalanceWidget.ObjectiveOut !== 'undefined')
            "
          >
            {{ (BalanceWidget.ObjectiveIn - BalanceWidget.ObjectiveOut) | getNumberFormat }}
            <span class="font-weight-bold text-muted font-size-sm float-right" v-translate>of the objective</span>
          </div>
        </div>

        <!-- Data row -->
        <div class="col-lg-6" :class="parameters !== null && typeof parameters.Expanded !== 'undefined' && parameters.Expanded ? '' : 'd-none'">
          <!--begin::Chart-->
          <div
            style="max-height: 140px"
            v-if="
              (BalanceWidget.ObjectiveIn !== 0 && typeof BalanceWidget.ObjectiveIn !== 'undefined') ||
              (BalanceWidget.ObjectiveOut !== 0 && typeof BalanceWidget.ObjectiveOut !== 'undefined')
            "
          >
            <apexchart :options="chartOptions" :series="getSeries()" type="radialBar"></apexchart>
          </div>
          <!--end::Chart-->
        </div>
      </div>

      <!--begin::Separator-->
      <div class="separator separator-solid my-4"></div>
      <!--end::Separator-->

      <div class="row row-col-separator-xl">
        <!--begin::Credit-->
        <div
          class="card card-custom card-transparent card-shadowless card-stretch --col-md-6 --col-lg-12"
          :class="parameters !== null && typeof parameters.Expanded !== 'undefined' && parameters.Expanded ? 'col-md-6' : 'col-lg-12'"
        >
          <div class="card-body my-4 p-0 pr-2">
            <span class="card-title font-weight-bolder text-opalean-info font-size-h6 mb-4 text-hover-state-dark d-block" v-translate>Credit</span>
            <div class="font-weight-bold text-dark-75 font-size-sm d-flex align-items-center justify-content-between h-30px">
              <span v-translate>I am owed</span>

              <b-skeleton-wrapper :loading="typeof BalanceWidget.Credit === 'undefined' || BalanceWidget.Credit === undefined" class="w-30">
                <template #loading>
                  <span><b-skeleton animation="fade"></b-skeleton></span>
                </template>
                <span class="--text-dark-75 text-opalean-info font-weight-bolder font-size-h2 ml-2 float-right"
                  >+{{ BalanceWidget.Credit | getNumberFormat }}</span
                >
              </b-skeleton-wrapper>
            </div>
            <div class="progress progress-xs mt-7 bg-opalean-info-o-60">
              <div
                class="progress-bar bg-opalean-info position-relative"
                role="progressbar"
                style="width: 87%; right: -13%"
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div
              class="font-weight-bold text-muted font-size-sm mt-1"
              v-if="BalanceWidget.ObjectiveIn !== 0 && typeof BalanceWidget.ObjectiveIn !== 'undefined'"
            >
              <span v-translate>Goal</span>
              <span class="float-right"> +{{ BalanceWidget.ObjectiveIn | getNumberFormat }}</span>
            </div>
          </div>
        </div>
        <!--end::Credit-->

        <!--begin::Debt-->
        <div
          class="card card-custom card-transparent card-shadowless card-stretch --gutter-b --col-md-6 --col-lg-12"
          :class="parameters !== null && typeof parameters.Expanded !== 'undefined' && parameters.Expanded ? 'col-md-6' : 'col-lg-12'"
        >
          <div class="card-body my-4 p-0 pr-2">
            <span class="card-title font-weight-bolder text-info font-size-h6 mb-4 text-hover-state-dark d-block" v-translate>Debt</span>
            <div class="font-weight-bold text-dark-75 font-size-sm d-flex align-items-center justify-content-between h-30px">
              <span v-translate>I owe</span>
              <b-skeleton-wrapper :loading="typeof BalanceWidget.Debt === 'undefined' || BalanceWidget.Debt === undefined" class="w-30">
                <template #loading>
                  <span><b-skeleton animation="fade"></b-skeleton></span>
                </template>
                <span class="--text-dark-75 text-info font-weight-bolder font-size-h2 ml-2 float-right">-{{ BalanceWidget.Debt | getNumberFormat }}</span>
              </b-skeleton-wrapper>
            </div>
            <div class="progress progress-xs mt-7 bg-info-o-60">
              <div
                class="progress-bar bg-info position-relative"
                role="progressbar"
                style="width: 75%"
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div
              class="font-weight-bold text-muted font-size-sm mt-1"
              v-if="BalanceWidget.ObjectiveOut != 0 && typeof BalanceWidget.ObjectiveOut !== 'undefined'"
            >
              <span v-translate>Goal</span>
              <span class="float-right"> +{{ BalanceWidget.ObjectiveOut | getNumberFormat }}</span>
            </div>
          </div>
        </div>
        <!--end::Debt-->
      </div>

      <!--end::Balance datas-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Balance Widget -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BalanceWidget",
  props: {
    expandable: Boolean,
    parameters: Object,
  },
  data() {
    return {
      checked: false,
      chartOptions: {},
      series: [],
    };
  },
  //components: {Spinner},
  computed: {
    ...mapGetters(["layoutConfig", "getDashboardByWidgetName"]),
    BalanceWidget: function () {
      return this.getDashboardByWidgetName("BalanceWidget")[0]; // Make sure we return a flat array of objects
    },
  },
  methods: {
    setCheck(check) {
      if (check) {
        this.checked = check;
      } else {
        this.checked = false;
      }
    },
    getSeries: function () {
      //@Gabriel : Total balance / (Objectif in + objectif out)
      return [
        Vue.filter("getNumberFormat")(
          Math.round((this.BalanceWidget.Credit - this.BalanceWidget.Debt) / (this.BalanceWidget.ObjectiveIn - this.BalanceWidget.ObjectiveOut))
        ),
      ];
    },
  },
  mounted() {
    this.chartOptions = {
      chart: {
        height: 280,
        type: "radialBar",
        offsetY: 0,
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          hollow: {
            margin: 0,
            size: "70%",
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: true,
              fontSize: "13px",
              fontWeight: "500",
              offsetY: -5,
              color: this.layoutConfig("colors.gray.gray-500"),
            },
            value: {
              color: this.layoutConfig("colors.gray.gray-700"),
              fontSize: "30px",
              fontWeight: "500",
              offsetY: -40,
              show: true,
            },
          },
          track: {
            background: this.layoutConfig("colors.theme.light.opalean-gray-medium"),
            strokeWidth: "100%",
          },
        },
      },
      colors: [this.layoutConfig("colors.theme.base.opalean-gray-medium")],
      stroke: {
        lineCap: "round",
      },
      labels: [this.$gettext("progress")],
    };
  },
};
</script>
