<template>
  <div>
    <h5 class="mb-10 font-weight-bolder" v-translate>Contact informations</h5>

    <div class="row">
      <div class="form-group col-4 mb-0">
        <FormGroupInput
          :value="syncedPartnerForm.PartnerDetails.Address1 + ', ' + syncedPartnerForm.Partner.ZipCode + ', ' + syncedPartnerForm.Partner.City"
          label="Address"
          input-id="partnerAddress"
          placeholder="Address"
          input-class="mb-5"
          :show-copy-button="true"
        />
        <FormGroupInput
          v-model="coordinateString"
          label="Coordinates"
          input-id="partnerCoordinates"
          placeholder="Coordinates"
          input-class="mb-5"
          :input-style="{ border: hasLatitudeError && hasLongitudeError ? '2px solid #e74a3b' : '' }"
        />
        <FormGroupInput
          v-model="syncedPartnerForm.PartnerDetails.Latitude"
          label="Latitude"
          input-id="partnerLatitude"
          placeholder="Latitude"
          input-class="mb-5"
          :input-style="{ border: hasLatitudeError ? '2px solid #e74a3b' : '' }"
        />
        <FormGroupInput
          v-model="syncedPartnerForm.PartnerDetails.Longitude"
          label="Longitude"
          input-id="partnerLongitude"
          placeholder="Longitude"
          input-class="mb-5"
          :input-style="{ border: hasLongitudeError ? '2px solid #e74a3b' : '' }"
        />
      </div>
      <div class="col-8 m-0">
        <iframe
          width="100%"
          height="340px"
          style="border: 0"
          loading="lazy"
          allowfullscreen
          referrerpolicy="no-referrer-when-downgrade"
          :src="
            'https://www.google.com/maps/embed/v1/place?key=' +
            api +
            '&q=' +
            syncedPartnerForm.PartnerDetails.Latitude +
            ', ' +
            syncedPartnerForm.PartnerDetails.Longitude +
            '&zoom=11' +
            '&maptype=satellite'
          "
        >
        </iframe>
      </div>
    </div>

    <div class="row">
      <FormGroupInput v-model="syncedPartnerForm.PartnerDetails.GroupA" label="Group A" input-id="partnerGroupA" placeholder="Group A" class="col" />
      <FormGroupInput v-model="syncedPartnerForm.PartnerDetails.GroupB" label="Group B" input-id="partnerGroupB" placeholder="Group B" class="col" />
    </div>
  </div>
</template>

<script>
import FormGroupInput from "@/view/content/neoopatrace/components/Inputs/FormGroupInput.vue";

export default {
  name: "wizard-partnerIdentification",
  components: {
    FormGroupInput,
  },
  props: {
    syncedPartnerForm: Object,
  },
  data() {
    return {
      syncedPartnerFormShowed: true,
      api: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    };
  },
  computed: {
    hasLatitudeError() {
      const lat = this.syncedPartnerForm.PartnerDetails.Latitude ?? "";
      const lon = this.syncedPartnerForm.PartnerDetails.Longitude ?? "";
      return (!(lat === "" && lon === "") && this.isInvalidCoordinate(lat, 90)) || (lat === "" && lon !== "");
    },
    hasLongitudeError() {
      const lat = this.syncedPartnerForm.PartnerDetails.Latitude ?? "";
      const lon = this.syncedPartnerForm.PartnerDetails.Longitude ?? "";
      return (!(lat === "" && lon === "") && this.isInvalidCoordinate(lon, 180)) || (lon === "" && lat !== "");
    },
    coordinateString: {
      get() {
        const lat = this.syncedPartnerForm.PartnerDetails.Latitude ?? "";
        const lon = this.syncedPartnerForm.PartnerDetails.Longitude ?? "";
        return lat === "" && lon === "" ? "" : `${lat}, ${lon}`;
      },
      set(value) {
        this.parseCoordinates(value);
      },
    },
  },
  methods: {
    isInvalidCoordinate(value, range) {
      if (value === "" /**|| value === null */ || value === undefined) return true;
      // Use a regular expression to check if the value is a valid number
      const isValidNumber = /^-?\d+(\.\d+)?$/.test(value);
      if (!isValidNumber) return true;
      const num = parseFloat(value);
      return !isFinite(num) || Math.abs(num) > range;
    },
    parseCoordinates(value) {
      const coords = value.split(",").map((coord) => coord.trim());
      if (coords.length === 2) {
        const [lat, lon] = coords;
        if (!this.isInvalidCoordinate(lat, 90) && !this.isInvalidCoordinate(lon, 180)) {
          this.syncedPartnerForm.PartnerDetails.Latitude = lat;
          this.syncedPartnerForm.PartnerDetails.Longitude = lon;
        }
      }
      if (value.length === 0) {
        this.syncedPartnerForm.PartnerDetails.Latitude = "";
        this.syncedPartnerForm.PartnerDetails.Longitude = "";
      }
    },
  },
};
</script>
