<template>
  <div>
    <!--begin::dashboardWidgets-->
    <b-card class="card-custom gutter-b mt-10" body-class="p-3 text-info" v-if="showDataDebug">
      {{ DashboardSettings }}
    </b-card>
    <div class="row d-flex draggable-zone" v-if="StoredDatas.datas.Dashboard !== undefined">
      <div
        :id="widgetKey"
        class="draggable"
        :class="[
          getWidgetSize(
            widgetKey,
            getWidgetExpandable(widgetKey) !== false && typeof widget.Expanded == 'boolean' && widget.Expanded === true ? widget.Expanded : undefined
          ),
          getWidgetExpandable(widgetKey) !== false ? (typeof widget.Expanded == 'boolean' && widget.Expanded === true ? 'expanded' : 'collapsed') : '',
        ]"
        v-for="(widget, widgetKey) in typeof DashboardSettings === 'object'
          ? Object.entries(DashboardSettings)
              .sort(([, a], [, b]) => a.Order - b.Order)
              .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
          : {}"
        :key="widgetKey"
        v-show="widget.Visible"
      >
        <keep-alive>
          <component v-bind:is="widgetKey" :expandable="getWidgetExpandable(widgetKey)" :parameters="widget" :key="$language.current"></component>
          <!-- https://stackoverflow.com/questions/55581316/delay-the-rendering-of-a-component-in-vue -->
        </keep-alive>
      </div>
    </div>
    <p class="mb-10" v-else><translate>Loading...</translate></p>
    <!--end::dashboardWidgets-->

    <!-- DEV -->
    <b-card class="card-custom gutter-b mt-10" body-class="p-3" v-if="showDataDebug">
      <pre><b>DashboardSettings:</b> {{ DashboardSettings }}</pre>
      <hr />
      <pre><b>Stored datas:</b> {{ StoredDatas }}</pre>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

/**
 * NeoOpatrace widgets
 */
import ActivityWidget from "@/view/content/neoopatrace/widgets/ActivityWidget.vue";
import BalanceWidget from "@/view/content/neoopatrace/widgets/BalanceWidget.vue";
import CreditsWidget from "@/view/content/neoopatrace/widgets/CreditsWidget.vue";
import DebtsWidget from "@/view/content/neoopatrace/widgets/DebtsWidget.vue";
import TotalsWidget from "@/view/content/neoopatrace/widgets/TotalsWidget.vue";
import AlertsWidget from "@/view/content/neoopatrace/widgets/AlertsWidget.vue";
import NewsWidget from "@/view/content/neoopatrace/widgets/NewsWidget.vue";
import ContactWidget from "@/view/content/neoopatrace/widgets/ContactWidget.vue";
import LatestOperationsWidget from "@/view/content/neoopatrace/widgets/LatestOperationsWidget.vue";

import { Sortable } from "@shopify/draggable";
/**
 * Initials
 */
import statics from "@/core/statics/statics.js";
import { mapState, mapGetters, mapActions } from "vuex";
//import ApiService from "@/core/services/api.service";
//import Swal from "sweetalert2";

export default {
  title() {
    return this.$gettext("menu.dashboard");
  },
  name: "Dashboard",
  components: {
    //NeoOpatrace
    ActivityWidget,
    BalanceWidget,
    CreditsWidget,
    DebtsWidget,
    TotalsWidget,
    AlertsWidget,
    NewsWidget,
    ContactWidget,
    LatestOperationsWidget,
  },
  data() {
    return {
      //Preferences
      showDataDebug: statics.showDataDebug,

      widgetSizes: statics.widgetSizes,
      //dashboardWidgets: []
    };
  },
  computed: {
    //A
    // ...mapGetters(["isAuthenticated", "getDashboardSettings"]),
    // DashboardSettings() {
    //   return _.orderBy(this.getDashboardSettings, "Order") ?? [];
    // },
    //B
    //https://woetflow.com/posts/using-v-model-to-bind-user-input-to-state-in-the-vuex-store/
    ...mapGetters(["isAuthenticated"]),
    ...mapState({ Settings: (state) => state.datas.Settings }),
    DashboardSettings: function () {
      //console.log("this.Settings.DashboardSettings:: length ", typeof this.Settings, typeof this.Settings.DashboardSettings.length);

      // Return Default if no settings
      // if (typeof this.Settings.DashboardSettings !== "undefined" && this.Settings.DashboardSettings.length > 0) return this.Settings.DashboardSettings;
      // else
      // return {
      //   ActivityWidget: {
      //     Name: "Activity",
      //     Order: 0,
      //     Visible: true,
      //   },
      //   BalanceWidget: {
      //     Name: "Balance",
      //     Expanded: false,
      //     Order: 1,
      //     Visible: true,
      //   },
      //   AlertsWidget: {
      //     Name: "Alerts",
      //     Order: 2,
      //     Visible: true,
      //   },
      //   CreditsWidget: {
      //     Name: "Credits",
      //     Order: 3,
      //     Visible: true,
      //   },
      //   DebtsWidget: {
      //     Name: "Debts",
      //     Order: 4,
      //     Visible: true,
      //   },
      //   TotalsWidget: {
      //     Name: "Totals",
      //     Order: 5,
      //     Visible: false,
      //   },
      //   LatestOperationsWidget: {
      //     Name: "Latest operations",
      //     Order: 6,
      //     Visible: true,
      //   },
      //   NewsWidget: {
      //     Name: "News",
      //     Order: 7,
      //     Visible: true,
      //   },
      //   ContactWidget: {
      //     Name: "Contact",
      //     Order: 8,
      //     Visible: true,
      //   },
      // }; // OBJECT

      // No default settings
      return this.Settings.DashboardSettings;
      //return _.orderBy(this.Settings.DashboardSettings, "Order") ?? []; >> Do not reorder object here because it breaks the drag and drop
    },
    // DEV > Show stored datas
    StoredDatas() {
      return this.$store.state;
    },
  },
  methods: {
    // ...mapMutations([
    //   "setDashboardSettings",
    //   "setDashboardSettingsWidgetOrder"
    // ]),
    ...mapActions(["changeWidgetOrder"]),
    getWidgetSize(widgetKey, expanded) {
      // Find size in statics
      console.log("getWidgetSize::", widgetKey, expanded, this.widgetSizes);

      const sizeMap = {
        sm: "col-xxl-3",
        md: "col-xxl-6",
        lg: "col-xxl-9",
        xl: "col-xxl-12",
      };

      const item = this.widgetSizes.find((i) => i.name === widgetKey);

      if (!item) {
        console.error(`@ERROR : name error here: Widget key does not exist: ${widgetKey}`);
        return sizeMap.md; // Default to medium size
      }

      const size = item.colSize ?? "md";
      const expandedSize = item.colSizeExpanded;

      return expanded && expandedSize ? sizeMap[expandedSize] : sizeMap[size];
    },
    getWidgetExpandable(widgetKey) {
      // Find size in statics
      console.log("getWidgetExpandable::", widgetKey, this.widgetSizes);
      var item = this.widgetSizes.find((i) => i.name == widgetKey);
      if (item == undefined) {
        console.log("@ERROR : name error here: this widgetKey does not exist :", widgetKey);
        return false;
      }
      return item.colSizeExpanded != undefined;
    },
    /*widgetOrderBy(data) {
      var data = typeof data !== "undefined" ? data : new Array();
      return data.slice().sort(function(a, b) {
        return a.Order - b.Order;
      });
    }*/
  },
  mounted() {
    console.info("Dashboard.vue");

    if (this.isAuthenticated) {
      // Get Dashboard
      this.$store.dispatch("loadDashboard").then(() => {
        console.log("loadDashboard");
      });

      //Breadcrumb > To dispatch to every pages
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);

      // Define dragging
      const sortable = new Sortable(document.querySelectorAll(".draggable-zone"), {
        draggable: ".draggable",
        handle: ".handle",
        mirror: {
          appendTo: "body",
          constrainDimensions: true,
        },
      });
      // Start Dragging
      sortable.on("sortable:start", (sortableEvent) => {
        console.log("sortable:start :: " + sortableEvent.data.dragEvent.data.source.id);
      });
      // On Drag event
      sortable.on("sortable:sorted", (sortableEvent) =>
        console.log("sortable:sorted :: " + sortableEvent.data.dragEvent.data.source.id + " from " + sortableEvent.oldIndex + " > " + sortableEvent.newIndex)
      );
      // When dragged
      sortable.on("sortable:stop", (sortableEvent) => {
        console.log("sortable:stop :: " + sortableEvent.data.dragEvent.data.source.id);
        // New method WILHEM

        let widgetKey = sortableEvent.data.dragEvent.data.source.id;
        let widgetOrder = 0;
        sortableEvent.data.newContainer.childNodes.forEach((widget, index) => {
          if ((widgetKey == widget.id && sortableEvent.newIndex == index) || widgetKey != widget.id) {
            console.log("STORE", widget.id, index, widgetOrder);

            // Without MAP
            // this.$store.dispatch("changeDashboardSettingsWidgetOrder", {
            //   widgetKey: widget.id,
            //   widgetOrder: widgetOrder
            // });

            // With MAP
            this.changeWidgetOrder({
              widgetKey: widget.id,
              widgetValue: widgetOrder,
            }); // OU setDashboardSettingsWidgetOrder ? quelle ≠ ?

            widgetOrder++;
          } else console.log("LEAVE", index);

          // Reorder
          /*this.dashboardWidgets = this.widgetOrderBy(
            this.dashboardWidgets,
            "Order"
          );*/
          // Now reorder in map
        });

        // Victor :
        /*var movingWidget = this.dashboardWidgets.findIndex(function(item) {
        if (item.Order == sortableEvent.oldIndex) return item;
      });
      var movedWidget = this.dashboardWidgets.findIndex(function(item) {
        if (item.Order == sortableEvent.newIndex) return item;
      });
      this.dashboardWidgets[movingWidget].Order = sortableEvent.newIndex;
      this.dashboardWidgets[movedWidget].Order = sortableEvent.oldIndex;*/

        // Store widgetOrder
        //this.setDashboardSettings(this.dashboardWidgets);
        //call API Setter here to update widgetOrder in db

        // Then store after a timeout
        // setTimeout(() => {
        //   console.log("DashboardSettings::POST API:: /user" + sortableEvent.data.dragEvent.data.source.id, this.Settings, this.Settings.DashboardSettings);

        //   const _DashboardSettings = _.orderBy(this.Settings.DashboardSettings, "Order");
        //   console.log("DashboardSettings::POST API:: /user >> REORDERED", _DashboardSettings);

        //   // Post User
        //   (async () => {
        //     let response = null;
        //     let errorStatus = null;
        //     let errorData = null;
        //     try {
        //       response = await ApiService.post("/user", { Settings: { DashboardSettings: _DashboardSettings } });
        //     } catch (error) {
        //       console.error("Error response:");
        //       console.error(error.response.data); // ***
        //       console.error(error.response.status); // ***
        //       console.error(error.response.headers); // ***

        //       errorStatus = error.response.status;
        //       errorData = error.response.data.errors.join(", ");
        //     } finally {
        //       console.log(typeof response);
        //       console.log(typeof errorStatus);
        //       console.log(typeof errorData);

        //       if (response === null && errorStatus !== null) {
        //         // Error
        //         Swal.fire({
        //           title: errorStatus,
        //           html: "Damned, we are sorry, we have an error..." + (errorData !== null ? "<br/><code> " + errorData + " </code>" : ""),
        //           icon: "error",
        //           confirmButtonClass: "btn btn-secondary",
        //           footer: '<a href="mailto:support@opalean.fr">' + this.$gettext('Need to contact support ?') + '</a>',
        //         });
        //       } else if (response !== null && response.status === 200) {
        //         // Success
        //         Swal.fire({
        //           title: "User settings",
        //           html: "The user settings has been successfully <em>stored</em> !",
        //           icon: "success",
        //           timer: 1200,
        //           confirmButtonClass: "btn btn-secondary",
        //         });
        //       }
        //     }
        //   })();
        // }, 500);
        // No stored via DropdownDashboard.vue Watcher
      });

      // First call define options widgetOrders and RewidgetOrder then
      /*this.dashboardWidgets = this.widgetOrderBy(
        this.dashboardWidgets.length < 1
          ? this.getDashboardSettings
          : this.dashboardWidgets,
        "Order"
      );*+

      // Victor
      /*    if (
      this.getDashboardSettings == undefined ||
      this.getDashboardSettings.length == 0
    ) {
      // Set dashboardWidgets
      this.dashboardWidgets = this.widgetOrderBy(
        this.getSettings.DashboardSettings,
        "Order"
      );

      console.log(this.dashboardWidgets);

      this.setDashboardSettings(this.dashboardWidgets);
    } else
      this.dashboardWidgets = this.widgetOrderBy(this.getDashboardSettings, "Order");
      */
    } else console.error("FATAL ERROR::Dashboard.vue isAuthenticated is false");
  },
};
</script>
