<template>
  <div>
    <!-- begin::Stocks -->
    <div class="card card-custom --card-stretch gutter-b">
      <!--begin::Body-->
      <div class="card-body p-0">
        <!-- Filters -->
        <v-app class="rounded-top-sm d-none d-sm-block">
          <b-button-group class="m-0 btn-block bg-opalean-gray-light rounded-top-0 rounded-sm">
            <!-- Filter button -->
            <b-button v-b-toggle.list-filters size="lg" block variant="opalean-gray-light" class="p-5 rounded-top-0 rounded-sm text-left font-weight-bold m-0"
              ><i class="flaticon2-dashboard"></i> <translate>Filters</translate>
              <span v-if="!haveAnyFilters()" class="float-right"><i class="la la-filter"></i></span>
            </b-button>

            <!-- Actions -->
            <b-dropdown
              squared
              variant="opalean-gray-light"
              class="w-10 --border-left --border-opalean-gray-medium"
              toggle-class="rounded-top-0 btn-hover-bg-opalean-gray-medium btn-hover-text-dark btn-hover-icon-dark"
              no-caret
              right
            >
              <template #button-content>
                <i class="la la-ellipsis-v icon-xl"></i>
              </template>

              <b-dropdown-header id="dropdown-header-1" v-translate>Export</b-dropdown-header>
              <b-dropdown-item>
                <vue-excel-xlsx
                  :data="this.excelFilteredItems"
                  :columns="columnsExcel"
                  :fileName="`${$gettext('Stocks')}_${getNow()}`"
                  :sheetName="$gettext('Stocks sheet')"
                  class="stretched-link"
                >
                  <translate>Excel (*.xls)</translate>
                </vue-excel-xlsx>
              </b-dropdown-item>
              <!-- <b-dropdown-item>
                <translate>Print (*.pdf)</translate>
                <span class="ml-2 label label-outline-info label-pill label-inline" v-translate>Coming soon</span>
              </b-dropdown-item> -->
            </b-dropdown>
          </b-button-group>
          <b-collapse id="list-filters" class="mt-0" v-model="collapsed">
            <b-card class="border-top-0 rounded-top-0 rounded-sm" body-class="px-5 bg-opalean-whiter">
              <p class="card-text font-size-lg font-weight-bold" v-translate>Filters</p>

              <div class="mt-2 row d-flex">
                <div class="col-12 col-sm-3 mr-2 mb-2">
                  <b-form-group
                    :label="$gettext('Search')"
                    label-class="text-dark font-size-xs font-weight-bolder text-uppercase"
                    :description="$gettext('Examples : Auchan, Grimonprez, Grim, 0003, AF, Opalean, Dusseldofer, 11/12...')"
                    label-for="filter-input"
                  >
                    <b-input-group size="lg" class="pt-3">
                      <b-form-input id="filter-input" v-model="search" type="search" :placeholder="$gettext('Type to search')"></b-form-input>

                      <b-input-group-append>
                        <b-button @click="search = ''">Clear</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>

                <div class="col-12 col-sm-4">
                  <label class="text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Total range</label>

                  <v-range-slider id="stockRange" v-model="stockRange" :max="stockMax" :min="stockMin" class="mt-2 d-flex">
                    <template v-slot:prepend>
                      <b-form-input
                        :value="stockRange[0]"
                        type="number"
                        size="sm"
                        style="width: 80px"
                        class="mt-n1"
                        @change="$set(stockRange, 0, $event)"
                      ></b-form-input>
                      <!--  v-model="stockRange[0]" -->
                    </template>
                    <template v-slot:append>
                      <b-form-input
                        :value="stockRange[1]"
                        type="number"
                        size="sm"
                        style="width: 80px"
                        class="mt-n1"
                        @change="$set(stockRange, 1, $event)"
                      ></b-form-input>
                      <!--  v-model="stockRange[1]" -->
                    </template>
                  </v-range-slider>
                </div>

                <FormFields v-for="key in fieldKeys" :key="key" :selectVal="selectVal" :fieldKey="key" :options="listOptions[key]"></FormFields>

                <b-col lg="12">
                  <b-button
                    block
                    variant="outline-opalean-gray-medium"
                    size="lg"
                    class="btn-hover-danger font-weight-bold"
                    v-if="!haveAnyFilters()"
                    :disabled="haveAnyFilters()"
                    @click="clearAllFilters($event)"
                    ><i class="flaticon2-refresh-button icon-md"></i> <translate>Clear all</translate></b-button
                  >
                </b-col>
              </div>
            </b-card>
          </b-collapse>
        </v-app>

        <!--begin::Table-->
        <v-app class="rounded-bottom-sm">
          <v-main>
            <v-data-table
              id="stocksTable"
              :headers="!$vuetify.breakpoint.xs ? headers : mobileHeaders"
              :items="Stocks"
              :page.sync="page"
              :items-per-page.sync="perPage"
              :footer-props="{
                'items-per-page-options': perPageChoices,
              }"
              hide-default-footer
              :search.sync="search"
              :item-class="rowClass"
              item-key="PalletID"
              sort-by="PalletID"
              class="table table-vertical-center table-responsive-sm bg-opalean-white mb-0"
              ref="tableItems"
              @current-items="onFiltered"
            >
              <template v-slot:[`item.PalletID`]="{ item }">
                <!-- Handle getPallet skeleton -->
                <b-skeleton-wrapper :loading="getPallet(item.PalletID) == undefined">
                  <template #loading>
                    <b-skeleton animation="fade" :width="Math.floor(50 + Math.random() * 50) + '%'"></b-skeleton>
                  </template>

                  <div class="d-flex justify-content-start align-items-center --py-5" v-if="getPallet(item.PalletID) !== undefined">
                    <span
                      class="symbol symbol-40 mr-3"
                      v-for="(role, index) in getPalletTypes(getPallet(item.PalletID).PalletType)"
                      :key="index"
                      :class="[`symbol-light-${role.class}`]"
                      :title="showPopovers ? $gettext('Pallet type') : null"
                      v-b-popover.hover.html="showPopovers ? `<h4 class='font-weight-bolder text-${role.class}'>${role.name}</h4> ${role.description}` : null"
                    >
                      <!--begin::Svg Icon -->
                      <span class="symbol-label font-size-h5 svg-icon svg-icon-md svg-icon-success">
                        <inline-svg :src="role.icon"></inline-svg>
                      </span>
                      <!--end::Svg Icon-->
                    </span>

                    <DetailsPanel
                      v-if="item.PalletID !== null && item.PalletID !== 0"
                      :title="getPallet(item.PalletID) | getSafeObjectValue('PalletName')"
                      :inverted="true"
                    >
                    </DetailsPanel>
                    <span v-else class="text-muted">—</span>
                  </div>
                </b-skeleton-wrapper>
              </template>

              <template v-slot:[`item.InHouse`]="{ item }">
                <div>
                  <span
                    v-if="item.Total && item.Waiting != null"
                    class="font-size-h4"
                    :class="item.Total - item.Waiting > 0 ? 'text-opalean-info' : item.Total - item.Waiting < 0 ? 'text-info' : ''"
                    >{{ (item.Total - item.Waiting) | getNumberFormat }}</span
                  >
                  <span v-else class="text-muted">—</span>
                </div>
              </template>

              <template v-slot:[`item.Waiting`]="{ item }">
                <div>
                  <span
                    v-if="item.Waiting != null"
                    class="font-size-h4"
                    :class="item.Waiting > 0 ? 'text-opalean-info' : item.Waiting < 0 ? 'text-info' : ''"
                    >{{ item.Waiting | getNumberFormat }}</span
                  >
                  <span v-else class="text-muted">—</span>
                </div>
              </template>

              <template v-slot:[`item.Total`]="{ item }">
                <div>
                  <span v-if="item.Total != null" class="font-size-h4" :class="item.Total > 0 ? 'text-opalean-info' : item.Total < 0 ? 'text-info' : ''">{{
                    item.Total | getNumberFormat
                  }}</span>
                  <span v-else class="text-muted">—</span>
                </div>
              </template>

              <template v-slot:[`item._PalletName`]="{}"> </template>

              <!-- Actions -->
              <template v-slot:[`item.`]="{ item }">
                <div class="d-flex align-items-center justify-content-center">
                  <!-- Actions dropdown -->
                  <b-dropdown variant="link" class="--p-3" toggle-class="text-decoration-none btn-hover-text-white btn-hover-icon-dark" no-caret>
                    <template #button-content>
                      <i class="la la-ellipsis-h icon-xl"></i>
                    </template>

                    <b-dropdown-header id="dropdown-header-1" v-translate>View</b-dropdown-header>

                    <b-dropdown-item
                      :to="{
                        name: 'route.accounts',
                        params: {
                          PalletName: item._PalletName, // TEMP : En attente des JSON
                        },
                      }"
                    >
                      <translate>In accounts</translate>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>

              <!-- Footer -->
              <template v-slot:footer>
                <v-row class="px-3 py-2 align-center">
                  <v-col
                    class="d-flex flex-column justify-content-center --align-items-center border-right border-bottom-0 border-opalean-gray-light text-dark-75"
                    v-if="filteredItemsLength !== itemsLength"
                  >
                    <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Filtered</label>
                    <span class="font-weight-bolder">{{ filteredItemsLength }} <translate>stocks</translate></span>
                  </v-col>

                  <v-col
                    class="d-flex flex-column justify-content-center --align-items-center border-right border-bottom-0 border-opalean-gray-light text-dark-75"
                  >
                    <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Total</label>
                    <span class="font-weight-bolder">{{ itemsLength }} <translate>stocks</translate></span>
                  </v-col>

                  <v-col
                    class="d-flex flex-column justify-content-center --align-items-center border-right border-bottom-0 border-opalean-gray-light text-dark-75"
                  >
                    <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Showing</label>
                    <v-select dense outlined hide-details :value="perPage" @change="perPage = parseInt($event, 10)" :items="perPageChoices"> </v-select>
                  </v-col>

                  <v-col
                    class="d-flex flex-column justify-content-center --align-items-center border-right-0 border-bottom-0 border-opalean-gray-light text-dark-75"
                  >
                    <label class="d-block text-dark font-size-xs font-weight-bolder text-uppercase" v-translate>Page</label>
                    <v-pagination v-model="page" :length="pageCount" class="pagination-start"></v-pagination>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-main>
        </v-app>
      </div>
    </div>
  </div>
  <!-- end:: Accounts -->
</template>

<script>
import * as moment from "moment";

import { mapGetters } from "vuex";
//import ApiService from "@/core/services/api.service";

import FormFields from "@/view/content/neoopatrace/components/FormFields.vue";
import DetailsPanel from "@/view/content/neoopatrace/components/DetailsPanel.vue";

import statics from "@/core/statics/statics.js";
import helpers from "@/core/statics/helpers.js";

export default {
  title() {
    return this.$gettext("menu.stocks");
  },
  components: {
    FormFields,
    DetailsPanel,
  },
  name: "listStocks",
  data() {
    return {
      // Preferences
      showPopovers: statics.showPopovers,
      // Excel
      columnsExcel: [
        {
          label: this.$gettext("Pallet ID"),
          field: "PalletID",
        },
        {
          label: this.$gettext("Pallet Name"),
          field: "_PalletName",
        },
        {
          label: this.$gettext("On the road"),
          field: "Waiting",
        },
        {
          label: this.$gettext("Total"),
          field: "Total",
        },
      ],
      excelFilteredItems: [],
      // Pagination
      page: 1,
      perPage: 20,
      perPageChoices: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "500", value: 500 },
      ],
      filteredItemsLength: 0,
      // Table group & filters
      search: "",
      collapsed: false, // Used to collapse filters panel when filter search by item
      stockRange: [0, 100], // Initial balance range
      stockMin: 0,
      stockMax: 0,
      //Table
      headers: [
        {
          text: this.$gettext("Pallet"),
          value: "PalletID",
          class: "font-size-h4",
        },
        {
          text: "_PalletName",
          value: "_PalletName",
          width: "0",
          sortable: false,
          class: "d-none",
          cellClass: "d-none",
        },
        {
          text: this.$gettext("In house"),
          value: "InHouse",
          // filter: (value) => {
          //   //if (this.stockRange[0] == this.stockMin && this.stockRange[1] == this.stockMax) return true;
          //   if (value >= this.stockRange[0] && value <= this.stockRange[1]) return true;
          //   else return false;
          // },
          width: "17%", //10%
          class: "font-size-h4 text-end",
          cellClass: "text-end",
        },
        {
          text: this.$gettext("On the road"),
          value: "Waiting",
          // filter: (value) => {
          //   //if (this.stockRange[0] == this.stockMin && this.stockRange[1] == this.stockMax) return true;
          //   if (value >= this.stockRange[0] && value <= this.stockRange[1]) return true;
          //   else return false;
          // },
          width: "17%", //10%
          class: "font-size-h4 text-end",
          cellClass: "text-end",
        },
        {
          text: this.$gettext("Total"),
          value: "Total",
          filter: (value) => {
            //if (this.stockRange[0] == this.stockMin && this.stockRange[1] == this.stockMax) return true;
            if (value >= this.stockRange[0] && value <= this.stockRange[1]) return true;
            else return false;
          },
          width: "17%", //10%
          class: "font-size-h4 text-end",
          cellClass: "text-end",
        },
        {
          text: this.$gettext("Actions"),
          value: "",
          width: "9%",
          sortable: false,
        },
      ],
      mobileHeaders: [
        {
          text: this.$gettext("Pallet"),
          value: "PalletID",
          class: "font-size-h4",
        },
        {
          text: this.$gettext("In house"),
          value: "InHouse",
          width: "17%", //10%
          class: "font-size-h4",
        },
        {
          text: this.$gettext("On the road"),
          value: "Waiting",
          width: "17%", //10%
          class: "font-size-h4",
        },
        {
          text: this.$gettext("Total"),
          value: "Total",
          // filter: (value) => {
          //   //if (this.stockRange[0] == this.stockMin && this.stockRange[1] == this.stockMax) return true;
          //   if (value >= this.stockRange[0] && value <= this.stockRange[1]) return true;
          //   else return false;
          // },
          width: "17%", //10%
          class: "font-size-h4",
        },
        {
          text: this.$gettext("Actions"),
          value: "",
          width: "9%",
          sortable: false,
        },
      ],
      selectVal: {},
      // Parent / child
      hasFormFields: true, // Helper to retrieve $aprent inside the FormFields.vue component
    };
  },
  mixins: [helpers],
  methods: {
    rowClass(item) {
      return item.Active === false ? "muted" : undefined;
    },
    haveAnyFilters() {
      return (this.search == "" || this.search == undefined) &&
        this.stockRange[0] == this.stockMin &&
        this.stockRange[1] == this.stockMax &&
        Object.keys(this.selectVal).every((k) => !this.selectVal[k].value)
        ? true
        : false;
    },
    clearAllFilters(e) {
      // Prevent default
      e.preventDefault();
      // Needs to debounce because it's happening right at the same time from unblur
      setTimeout(() => {
        this.search = "";
        this.stockRange = [this.stockMin, this.stockMax];
        Object.keys(this.selectVal).forEach((key) => {
          this.selectVal[key].value = false;
        });
      }, 100);
    },
    // Filtering
    onFiltered(filteredItems) {
      console.log(
        "onFiltered::",
        filteredItems,
        this.haveAnyFilters(),
        typeof this.$refs.tableItems !== "undefined" ? this.$refs.tableItems.$children[0].filteredItems.length : undefined
      );
      // Store updated items length
      // Only if we look for a filter ( not perPage filter )
      if (!this.haveAnyFilters())
        this.filteredItemsLength = this.$refs.tableItems !== undefined ? this.$refs.tableItems.$children[0].filteredItems.length : filteredItems.length;
      else this.filteredItemsLength = this.itemsLength;

      // Then update excel filters
      this.setExcelFilter(filteredItems);
    },
    setExcelFilter() {
      if (this.$refs.tableItems !== undefined) this.excelFilteredItems = this.$refs.tableItems.$children[0].filteredItems;
      else this.excelFilteredItems = this.Stocks;
    },
    getNow() {
      return moment().format("DDMMYYYY_h-mm");
    },
  },
  computed: {
    ...mapGetters(["getStocks", "getPallet"]),
    // Data
    Stocks() {
      return this.getStocks;
    },
    // Pagination
    itemsLength() {
      return this.Stocks.length ?? 0;
    },
    pageCount() {
      console.log("pageCount::", this.filteredItemsLength, this.itemsLength, Math.ceil(this.filteredItemsLength / this.perPage) ?? 1);
      return Math.ceil(this.filteredItemsLength / this.perPage) ?? 1;
    },
    // Options
    fieldKeys() {
      return Object.keys(this.selectVal);
    },
    listOptions() {
      // This could be simplified if the select was it's own component.
      const listOptions = {};
      this.fieldKeys.forEach((key) => {
        if (this.selectVal[key].type == "select") {
          const vals = this.Stocks.map((item) => item[key]);
          console.log("listOptions::key", vals);
          // Cheap and efficient unique.
          listOptions[key] = Array.from(new Set(vals));
        }
      });
      listOptions.CountryISO.sort();

      console.log("listOptions::", listOptions);
      return listOptions;
    },
  },
  watch: {
    Stocks: {
      //deep: true, // Detect nested value changes inside Objects
      immediate: true, // Initiate at first load, trigger the callback immediately with the current value of the expression
      handler: function (newStocks) {
        console.log("Watch::Stocks", typeof newStocks, newStocks);
        // To fix filter issue if no Stocks loaded yet
        if (typeof newStocks !== "undefined" && newStocks.length > 0) {
          console.log("Watch::Stocks length=" + newStocks.length);
          // Stocks calculation
          let _Totals = newStocks.map((item) => item.Total);
          this.stockMin = this.stockRange[0] = Math.min.apply(null, _Totals);
          this.stockMax = this.stockRange[1] = Math.max.apply(null, _Totals);

          // Add labels for filters ( NDW : see it's not to busy to load )
          newStocks.forEach((s) => {
            // Pallets
            if (s.PalletID !== undefined) {
              console.log("Watch::Stocks", typeof this.getPallet(s.PalletID));
              if (typeof this.getPallet(s.PalletID) !== "undefined") s._PalletName = this.getPallet(s.PalletID).PalletName;
              else s._PalletName = "—";
            }
          });
        }
      },
    },
  },
  mounted() {
    console.info("ListStocks.vue");
    // Set filtered items length
    this.filteredItemsLength = this.Stocks.length;
  },
  // https://jerickson.net/created-vs-mounted-in-vue/
  created() {
    // Load Balances
    this.$store.dispatch("loadBalances").then(() => {
      console.log("loadBalances");
    });
  },
};
</script>
