<template>
  <div>
    <section id="synced_map" class="position-relative">
      <div class="position-absolute bottom-0 left-0 w-100 zindex-5">
        <div class="card card-custom mx-auto mb-10 w-90">
          <div class="card-body py-4">
            <!--begin::Bottom-->
            <div class="d-flex align-items-center flex-wrap">
              <!--begin: Item-->
              <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
                <span class="svg-icon svg-icon-3x svg-icon-opalean-gray-medium mr-4">
                  <!--begin::Svg Icon -->
                  <!-- Pour victor > rendre dynamique avec le partnerMainRoleTypes.id retenu ( create ) et / ou avec le role quand cela vient d'un edit -->
                  <inline-svg
                    src="media/svg/icons/Navigation/search.svg
                "
                  ></inline-svg>
                  <!--end::Svg Icon-->
                </span>

                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">Localize</span> {{ dragging }}
                  <div class="form-group col-12 mb-0 d-flex">
                    <GmapAutocomplete @place_changed="setPlace" />
                    <button @click="centerPlace">Go</button>
                    <button @click="geolocationMap">Find me</button>
                  </div>
                </div>
              </div>
              <!--end: Item-->
              <!--begin: Item-->
              <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
                <span class="svg-icon svg-icon-3x svg-icon-opalean-info mr-4">
                  <!--begin::Svg Icon -->
                  <!-- Pour victor > rendre dynamique avec le partnerMainRoleTypes.id retenu ( create ) et / ou avec le role quand cela vient d'un edit -->
                  <inline-svg
                    src="media/svg/icons/Design/Layers.svg
                "
                  ></inline-svg>
                  <!--end::Svg Icon-->
                </span>

                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">Credits</span>
                  <span class="--text-dark-50 font-weight-bolder font-size-h5 text-opalean-info">
                    <!-- — = class:text-dark-50-->
                    +22 789</span
                  >
                </div>
              </div>
              <!--end: Item-->
              <!--begin: Item-->
              <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
                <span class="svg-icon svg-icon-3x svg-icon-info mr-4">
                  <!--begin::Svg Icon -->
                  <inline-svg src="media/svg/icons/Design/Layers.svg"> </inline-svg>
                  <!--end::Svg Icon-->
                </span>

                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">Debts</span>
                  <span class="--text-dark-50 font-weight-bolder font-size-h5 text-info">
                    <!-- — = class:text-dark-50-->
                    -1043</span
                  >
                </div>
              </div>
              <!--end: Item-->
              <!--begin: Item-->
              <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
                <span class="svg-icon svg-icon-3x svg-icon-opalean-gray-medium mr-4">
                  <i class="flaticon2-cube icon-lg"></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm">Stocks</span>
                  <span class="text-dark-50 font-weight-bolder font-size-h5">
                    <!-- — = class:text-dark-50-->
                    Lorem ipsum</span
                  >
                </div>
              </div>
              <!--end: Item-->
            </div>
            <!--end::Bottom-->
          </div>
        </div>
      </div>

      <!-- Map A -->
      <GmapMap
        ref="mapA"
        @zoom_changed="zoomMap($event, 'mapB')"
        @center_changed="centerMap($event, 'mapB')"
        @dragstart="dragMap('start', 'mapA')"
        @dragend="dragMap('end', 'mapA')"
        :center="initialCenter"
        :zoom="initialZoom"
        class="fh-in-content half-fw-in-content ml-n1"
        :options="mapOptions"
      >
        <GmapCluster :zoomOnClick="true" :options="clusterOptions()" @click="dragMap('click', 'mapA')">
          <GmapMarker v-for="(m, index) in markersA" :key="index" :position="m.position" :draggable="false" @dragend="updateCoordinates($event.latLng, 'A')" />
        </GmapCluster>
      </GmapMap>

      <!-- Map B -->
      <GmapMap
        ref="mapB"
        @zoom_changed="zoomMap($event, 'mapA')"
        @center_changed="centerMap($event, 'mapA')"
        @dragstart="dragMap('start', 'mapB')"
        @dragend="dragMap('end', 'mapB')"
        :center="initialCenter"
        :zoom="initialZoom"
        class="fh-in-content half-fw-in-content ml-1"
        :options="mapOptions"
      >
        <GmapCluster :zoomOnClick="true" :options="clusterOptions()" @click="dragMap('click', 'mapB')">
          <GmapMarker v-for="(m, index) in markersB" :key="index" :position="m.position" :draggable="false" @dragend="updateCoordinates($event.latLng, 'B')" />
          <GmapMarker
            v-for="(m, index) in palBank"
            :key="'palBank' + index"
            :position="m.position"
            :label="markerLabel(m.EPAL_TV.toString())"
            :icon="markerIcon(m.EPAL_TV.toString())"
            :draggable="false"
            @click="
              initialCenter = m.position;
              initialZoom = 17;
            "
          />
        </GmapCluster>
      </GmapMap>
    </section>
  </div>
</template>

<script>
//http://xkjyeah.github.io/vue-google-maps/index-app.html#/03CurvedLine
//http://xkjyeah.github.io/vue-google-maps/autoapi.html
//https://github.com/xkjyeah/vue-google-maps/tree/8d6bbac96b0797cf1e5b9537d58920c23ba75bd2/examples
//https://googlemaps.github.io/js-markerclusterer/public/renderers/
//https://developers.google.com/maps/documentation/javascript/examples/map-sync
//https://developers.google.com/maps/documentation/javascript/reference/control#ZoomControlOptions
//https://diegoazh.github.io/gmap-vue/guide/marker.html#description
//https://googlemaps.github.io/js-markerclusterer/classes/MarkerClusterer.html
//https://developers.google.com/maps/documentation/javascript/events

import { components } from "gmap-vue";

import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module.js";
import statics from "@/core/statics/statics.js";

export default {
  name: "Map",
  components: {
    GmapCluster: components.Cluster,
  },
  data() {
    return {
      initialCenter: { lat: 46.52863469527167, lng: 2.43896484375 },
      initialZoom: 7,
      currentPlace: null,
      markersA: [{ position: { lat: 50.63289494409323, lng: 3.1360703468322647 } }, { position: { lat: 50.63289494409323, lng: 3.1360703468322647 } }],
      markersB: [{ position: { lat: 40.63289494409323, lng: 3.1360703468322647 } }, { position: { lat: 46.63289494409323, lng: 3.1360703468322647 } }],

      // Events
      googleMapsInitialized: false,
      dragging: { mapA: false, mapB: false },

      //Preferences
      showDataDebug: statics.showDataDebug,

      // Statics
      mapOptions: statics.mapOptions,

      // PROD
      palBank: [
        {
          NOM: "3T CONCEPT",
          PAYS: "FR",
          CP: "60660",
          VILLE: "ROUSSELOY",
          position: { lat: 49.29976, lng: 2.39701 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 108,
        },
        {
          NOM: "A BLEGER",
          PAYS: "FR",
          CP: "67600",
          VILLE: "HILSENHEIM",
          position: { lat: 48.290015, lng: 7.573328 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 412,
        },
        {
          NOM: "AEM LA SOUTERRAINE",
          PAYS: "FR",
          CP: "23300",
          VILLE: "LA SOUTERRAINE",
          position: { lat: 46.243873, lng: 1.49198 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "AGENEAU ARNAGE",
          PAYS: "FR",
          CP: "72230",
          VILLE: "ARNAGE",
          position: { lat: 47.948518, lng: 0.179472 },
          DEPOSE: "N",
          REPRISE: "N",
          EPAL_TV: 0,
        },
        {
          NOM: "AGENEAU TRANSPORTS",
          PAYS: "FR",
          CP: "44980",
          VILLE: "STE LUCE SUR LOIRE",
          position: { lat: 47.26726, lng: -1.462909 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 261,
        },
        {
          NOM: "AIO",
          PAYS: "FR",
          CP: "32300",
          VILLE: "LAMAZERE",
          position: { lat: 43.558759, lng: 0.444166 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 206,
        },
        {
          NOM: "AJV TRANSPORTS",
          PAYS: "FR",
          CP: "22170",
          VILLE: "PLELO",
          position: { lat: 48.53034, lng: -2.93351 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "ALAIN POSTIC TRANSPORTS",
          PAYS: "FR",
          CP: "56920",
          VILLE: "ST GONNERY",
          position: { lat: 48.12298, lng: -2.8468 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "ALAINE SUD EST",
          PAYS: "FR",
          CP: "30000",
          VILLE: "NIMES",
          position: { lat: 43.83868, lng: 4.42652 },
          DEPOSE: "N",
          REPRISE: "N",
          EPAL_TV: 0,
        },
        {
          NOM: "ALEXTREM TRANSPORTS",
          PAYS: "FR",
          CP: "30510",
          VILLE: "GENERAC",
          position: { lat: 43.745037, lng: 4.343127 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1127,
        },
        {
          NOM: "ALTRANS CENTRE (BRUNO ROBERT)",
          PAYS: "FR",
          CP: "41700",
          VILLE: "CONTRES",
          position: { lat: 47.430552, lng: 1.444261 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1,
        },
        {
          NOM: "ANTIGNAC SARL",
          PAYS: "FR",
          CP: "19410",
          VILLE: "ST BONNET L ENFANTIER",
          position: { lat: 45.303404, lng: 1.509296 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1,
        },
        {
          NOM: "ARCHER",
          PAYS: "FR",
          CP: "43700",
          VILLE: "ST GERMAIN LAPRADE",
          position: { lat: 45.050545, lng: 3.974275 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 32,
        },
        {
          NOM: "ARTK",
          PAYS: "FR",
          CP: "57300",
          VILLE: "TREMERY",
          position: { lat: 49.11494, lng: 6.23329 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 522,
        },
        {
          NOM: "ARTON TRANSPORTS",
          PAYS: "FR",
          CP: "21140",
          VILLE: "SEMUR EN AUXOIS",
          position: { lat: 47.49026, lng: 4.34193 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 100,
        },
        {
          NOM: "ATR RIOM",
          PAYS: "FR",
          CP: "63200",
          VILLE: "RIOM",
          position: { lat: 45.879325, lng: 3.125505 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 76,
        },
        {
          NOM: "AUBRY LOGISTIQUE",
          PAYS: "FR",
          CP: "88700",
          VILLE: "RAMBERVILLERS",
          position: { lat: 48.342014, lng: 6.657447 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 35,
        },
        {
          NOM: "B ET B TRANSPORTS",
          PAYS: "FR",
          CP: "57245",
          VILLE: "JURY",
          position: { lat: 49.06623, lng: 6.24939 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 511,
        },
        {
          NOM: "BAH-TRANS",
          PAYS: "FR",
          CP: "16710",
          VILLE: "ST YRIEIX SUR CHARENTE",
          position: { lat: 45.67574, lng: 0.14111 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 57,
        },
        {
          NOM: "BALLET TRANSPORTS",
          PAYS: "FR",
          CP: "70200",
          VILLE: "FROTEY LES LURE",
          position: { lat: 47.650806, lng: 6.552137 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 490,
        },
        {
          NOM: "BARBIER TRANSPORTS",
          PAYS: "FR",
          CP: "70000",
          VILLE: "NOIDANS LES VESOUL",
          position: { lat: 47.61958, lng: 6.13149 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 661,
        },
        {
          NOM: "BARNAUD TRANSPORTS",
          PAYS: "FR",
          CP: "71120",
          VILLE: "VENDENESSE LES CHAROLLES",
          position: { lat: 46.44296, lng: 4.30909 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1323,
        },
        {
          NOM: "BAROM LOGISTIQUE",
          PAYS: "FR",
          CP: "34740",
          VILLE: "VENDARGUES",
          position: { lat: 43.65117, lng: 3.95024 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 523,
        },
        {
          NOM: "BARON SAS",
          PAYS: "FR",
          CP: "80000",
          VILLE: "AMIENS",
          position: { lat: 49.930724, lng: 2.26272 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1670,
        },
        {
          NOM: "BAUDOUIN ET FILS",
          PAYS: "FR",
          CP: "79230",
          VILLE: "PRAHECQ",
          position: { lat: 46.269744, lng: -0.374916 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "BECKER SAS",
          PAYS: "FR",
          CP: "71880",
          VILLE: "CHATENOY LE ROYAL",
          position: { lat: 46.797878, lng: 4.831213 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 752,
        },
        {
          NOM: "BERT 42",
          PAYS: "FR",
          CP: "42450",
          VILLE: "SURY LE COMTAL",
          position: { lat: 45.532794, lng: 4.193929 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 27,
        },
        {
          NOM: "BERT NIMES",
          PAYS: "FR",
          CP: "30128",
          VILLE: "GARONS",
          position: { lat: 43.754674, lng: 4.436398 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "BERT PROVENCE",
          PAYS: "FR",
          CP: "84700",
          VILLE: "SORGUES",
          position: { lat: 44.025223, lng: 4.886802 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 1716,
        },
        {
          NOM: "BERT RHONE ALPES",
          PAYS: "FR",
          CP: "69330",
          VILLE: "MEYZIEU",
          position: { lat: 45.777929, lng: 5.022161 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 630,
        },
        {
          NOM: "BERT TRANSPORTS ET SERVICES",
          PAYS: "FR",
          CP: "26140",
          VILLE: "ST RAMBERT D ALBON",
          position: { lat: 45.278329, lng: 4.8204 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "BERTO GOUPILLE LANDRIAU",
          PAYS: "FR",
          CP: "85290",
          VILLE: "ST LAURENT SUR SEVRE",
          position: { lat: 46.956404, lng: -0.909854 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 145,
        },
        {
          NOM: "BIG BIG",
          PAYS: "FR",
          CP: "24110",
          VILLE: "ST ASTIER",
          position: { lat: 45.13837, lng: 0.53944 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 122,
        },
        {
          NOM: "BLANCHARD COUTAND",
          PAYS: "FR",
          CP: "85110",
          VILLE: "ST PROUANT",
          position: { lat: 46.756716, lng: -0.957843 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 3,
        },
        {
          NOM: "BLUEROAD",
          PAYS: "FR",
          CP: "56140",
          VILLE: "MALESTROIT",
          position: { lat: 47.800639, lng: -2.39892 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 45,
        },
        {
          NOM: "BNH TRANS",
          PAYS: "BE",
          CP: "4530",
          VILLE: "VILLERS-LE-BOUILLET",
          position: { lat: 50.58154, lng: 5.27102 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 271,
        },
        {
          NOM: "BOGAERT SOLUTION LOGISTIQUE",
          PAYS: "FR",
          CP: "59279",
          VILLE: "LOON PLAGE",
          position: { lat: 51.007775, lng: 2.192291 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 598,
        },
        {
          NOM: "BOUEIX",
          PAYS: "FR",
          CP: "33290",
          VILLE: "BLANQUEFORT",
          position: { lat: 44.926348, lng: -0.61603 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 210,
        },
        {
          NOM: "BOURGET TRANSPORTS SARL",
          PAYS: "FR",
          CP: "85540",
          VILLE: "MOUTIERS LES MAUXFAITS",
          position: { lat: 46.505, lng: -1.43154 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 13,
        },
        {
          NOM: "BOURGEY MONTREUIL RHONE ALPES",
          PAYS: "FR",
          CP: "69200",
          VILLE: "VENISSIEUX",
          position: { lat: 45.68695, lng: 4.89846 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1189,
        },
        {
          NOM: "BOURRAT DISTRIBUTION",
          PAYS: "FR",
          CP: "03100",
          VILLE: "MONTLUCON",
          position: { lat: 46.358209, lng: 2.604591 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 2964,
        },
        {
          NOM: "BOURRAT TRANSPORTS",
          PAYS: "FR",
          CP: "03400",
          VILLE: "YZEURE",
          position: { lat: 46.547593, lng: 3.348351 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1802,
        },
        {
          NOM: "BRAY TRANSPORTS",
          PAYS: "FR",
          CP: "62680",
          VILLE: "MERICOURT",
          position: { lat: 50.396992, lng: 2.862264 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 2821,
        },
        {
          NOM: "BRELET TRANSPORTS",
          PAYS: "FR",
          CP: "91460",
          VILLE: "MARCOUSSIS",
          position: { lat: 48.6654, lng: 2.20857 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 438,
        },
        {
          NOM: "BROSSIER TRANSPORTS",
          PAYS: "FR",
          CP: "07340",
          VILLE: "ST DESIRAT",
          position: { lat: 45.25525, lng: 4.69625 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 49,
        },
        {
          NOM: "BURBAN PALETTES CHEVILLY",
          PAYS: "FR",
          CP: "94550",
          VILLE: "CHEVILLY LARUE",
          position: { lat: 48.763268, lng: 2.340016 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 932,
        },
        {
          NOM: "BURBAN PALETTES CHEVROLIERE",
          PAYS: "FR",
          CP: "44118",
          VILLE: "LA CHEVROLIERE",
          position: { lat: 47.089151, lng: -1.551818 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 53,
        },
        {
          NOM: "BURBAN PALETTES COURRIERES",
          PAYS: "FR",
          CP: "62710",
          VILLE: "COURRIERES",
          position: { lat: 50.443042, lng: 2.920723 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 1952,
        },
        {
          NOM: "BURBAN PALETTES DOMARIN",
          PAYS: "FR",
          CP: "38300",
          VILLE: "DOMARIN",
          position: { lat: 45.588671, lng: 5.244303 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1155,
        },
        {
          NOM: "BURBAN PALETTES FLINS",
          PAYS: "FR",
          CP: "78410",
          VILLE: "FLINS SUR SEINE",
          position: { lat: 48.973729, lng: 1.86235 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 188,
        },
        {
          NOM: "BURBAN PALETTES GUEGON",
          PAYS: "FR",
          CP: "56120",
          VILLE: "GUEGON",
          position: { lat: 47.950195, lng: -2.579644 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1,
        },
        {
          NOM: "BURBAN PALETTES LAGNY",
          PAYS: "FR",
          CP: "77400",
          VILLE: "ST THIBAULT DES VIGNES",
          position: { lat: 48.874533, lng: 2.671683 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1287,
        },
        {
          NOM: "BURBAN PALETTES LE HAVRE",
          PAYS: "FR",
          CP: "76110",
          VILLE: "ST SAUVEUR D EMALLEVILLE",
          position: { lat: 49.615571, lng: 0.298007 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 66,
        },
        {
          NOM: "BURBAN PALETTES NAB",
          PAYS: "FR",
          CP: "45170",
          VILLE: "NEUVILLE AUX BOIS",
          position: { lat: 48.061958, lng: 2.060773 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 6,
        },
        {
          NOM: "BURBAN PALETTES ORMES",
          PAYS: "FR",
          CP: "45140",
          VILLE: "ORMES",
          position: { lat: 47.945014, lng: 1.84245 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 528,
        },
        {
          NOM: "BURBAN PALETTES ORVAL",
          PAYS: "FR",
          CP: "18200",
          VILLE: "ORVAL",
          position: { lat: 46.729343, lng: 2.467267 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 472,
        },
        {
          NOM: "BURBAN PALETTES ST ALBAN",
          PAYS: "FR",
          CP: "31140",
          VILLE: "ST ALBAN",
          position: { lat: 43.691433, lng: 1.4097 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 1343,
        },
        {
          NOM: "CANTAL FRET",
          PAYS: "FR",
          CP: "15000",
          VILLE: "AURILLAC",
          position: { lat: 44.909461, lng: 2.454094 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 85,
        },
        {
          NOM: "CAP LOGISTIQUE 35",
          PAYS: "FR",
          CP: "35230",
          VILLE: "ORGERES",
          position: { lat: 47.96026, lng: -1.68292 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 25,
        },
        {
          NOM: "CAP SOLUTION",
          PAYS: "FR",
          CP: "45770",
          VILLE: "SARAN",
          position: { lat: 47.949341, lng: 1.863021 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 385,
        },
        {
          NOM: "CARDINAL",
          PAYS: "FR",
          CP: "31790",
          VILLE: "ST JORY",
          position: { lat: 43.759488, lng: 1.362629 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 3095,
        },
        {
          NOM: "CARENTOIR ENTREPOT",
          PAYS: "FR",
          CP: "56910",
          VILLE: "CARENTOIR",
          position: { lat: 47.805578, lng: -2.137105 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "CARPENTIER LOGISTIQUE",
          PAYS: "FR",
          CP: "62100",
          VILLE: "CALAIS",
          position: { lat: 50.953894, lng: 1.918168 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 158,
        },
        {
          NOM: "CATROUX TRANSPORTS",
          PAYS: "FR",
          CP: "41330",
          VILLE: "FOSSE",
          position: { lat: 47.631067, lng: 1.292704 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 83,
        },
        {
          NOM: "CAVALLIN TRANSPORTS",
          PAYS: "FR",
          CP: "47240",
          VILLE: "BON ENCONTRE",
          position: { lat: 44.185224, lng: 0.682974 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1477,
        },
        {
          NOM: "C'EXPRESS TRANSPORT",
          PAYS: "FR",
          CP: "31620",
          VILLE: "CASTELNAU D ESTRETEFONDS",
          position: { lat: 43.77541, lng: 1.35564 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 987,
        },
        {
          NOM: "CHARBONNIER",
          PAYS: "FR",
          CP: "41200",
          VILLE: "ROMORANTIN LANTHENAY",
          position: { lat: 47.368467, lng: 1.712219 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 6,
        },
        {
          NOM: "CHARPENTIER TRANSPORTS",
          PAYS: "FR",
          CP: "44330",
          VILLE: "LE PALLET",
          position: { lat: 47.156558, lng: -1.367075 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 12,
        },
        {
          NOM: "CHAVIGNY",
          PAYS: "FR",
          CP: "41100",
          VILLE: "ST OUEN",
          position: { lat: 47.806358, lng: 1.082563 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "CHAZOT ST ETIENNE",
          PAYS: "FR",
          CP: "42000",
          VILLE: "ST ETIENNE",
          position: { lat: 45.44213, lng: 4.435005 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1544,
        },
        {
          NOM: "CHAZOT VILLENAVE D'ORNON",
          PAYS: "FR",
          CP: "33140",
          VILLE: "VILLENAVE D ORNON",
          position: { lat: 44.777134, lng: -0.547287 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "CHERRIER TRANSPORTS",
          PAYS: "FR",
          CP: "54710",
          VILLE: "FLEVILLE DEVANT NANCY",
          position: { lat: 48.616271, lng: 6.205748 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 337,
        },
        {
          NOM: "CHOPPIN PASCAL TRANSPORTS",
          PAYS: "FR",
          CP: "51300",
          VILLE: "MAROLLES",
          position: { lat: 48.72192, lng: 4.61526 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 821,
        },
        {
          NOM: "CHRONO ROUTE IDF - TC60",
          PAYS: "FR",
          CP: "95640",
          VILLE: "MARINES",
          position: { lat: 49.145704, lng: 1.96973 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 373,
        },
        {
          NOM: "CITRA",
          PAYS: "FR",
          CP: "02100",
          VILLE: "ST QUENTIN",
          position: { lat: 49.86831, lng: 3.322026 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 519,
        },
        {
          NOM: "CLIVOT TRANSPORTS",
          PAYS: "FR",
          CP: "10300",
          VILLE: "STE SAVINE",
          position: { lat: 48.298063, lng: 4.014449 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 319,
        },
        {
          NOM: "COBIGO",
          PAYS: "FR",
          CP: "56150",
          VILLE: "BAUD",
          position: { lat: 47.90115, lng: -3.012228 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "COLINET TRANSPORTS",
          PAYS: "FR",
          CP: "25300",
          VILLE: "VUILLECIN",
          position: { lat: 46.938052, lng: 6.331 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 302,
        },
        {
          NOM: "COMALDIS VERNOUILLET",
          PAYS: "FR",
          CP: "28500",
          VILLE: "VERNOUILLET",
          position: { lat: 48.712143, lng: 1.364465 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 68,
        },
        {
          NOM: "COMBRONDE PACKAGING 63 PSC",
          PAYS: "FR",
          CP: "63300",
          VILLE: "THIERS",
          position: { lat: 45.85775, lng: 3.49622 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 200,
        },
        {
          NOM: "COOTRALIM",
          PAYS: "FR",
          CP: "19100",
          VILLE: "BRIVE LA GAILLARDE",
          position: { lat: 45.15647, lng: 1.47516 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 3,
        },
        {
          NOM: "COTRAMA SARL",
          PAYS: "FR",
          CP: "62200",
          VILLE: "ST MARTIN BOULOGNE",
          position: { lat: 50.730918, lng: 1.674386 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1213,
        },
        {
          NOM: "COUE TRANSPORTS SAS",
          PAYS: "FR",
          CP: "53960",
          VILLE: "BONCHAMP LES LAVAL",
          position: { lat: 48.076508, lng: -0.722007 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 13,
        },
        {
          NOM: "CPI TRANSPORTS",
          PAYS: "FR",
          CP: "77183",
          VILLE: "CROISSY BEAUBOURG",
          position: { lat: 48.827412, lng: 2.639114 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 939,
        },
        {
          NOM: "CQFD TRANSPORTS",
          PAYS: "FR",
          CP: "18000",
          VILLE: "BOURGES",
          position: { lat: 47.053777, lng: 2.341814 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 429,
        },
        {
          NOM: "CRAS TRANSPORTS",
          PAYS: "FR",
          CP: "29150",
          VILLE: "CHATEAULIN",
          position: { lat: 48.21363, lng: -4.0565 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 8,
        },
        {
          NOM: "CRISPA PALETTES 01",
          PAYS: "FR",
          CP: "01150",
          VILLE: "LEYMENT",
          position: { lat: 45.93079, lng: 5.28539 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1127,
        },
        {
          NOM: "CRISPA PALETTES 34",
          PAYS: "FR",
          CP: "34110",
          VILLE: "FRONTIGNAN",
          position: { lat: 43.43654, lng: 3.70481 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 932,
        },
        {
          NOM: "CTE TRANSPORTS",
          PAYS: "FR",
          CP: "93150",
          VILLE: "LE BLANC MESNIL",
          position: { lat: 48.95343, lng: 2.45726 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 903,
        },
        {
          NOM: "DABRIGEON",
          PAYS: "FR",
          CP: "03800",
          VILLE: "GANNAT",
          position: { lat: 46.111232, lng: 3.2051 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 122,
        },
        {
          NOM: "DENOUAL CHEVIGNY",
          PAYS: "FR",
          CP: "21800",
          VILLE: "CHEVIGNY ST SAUVEUR",
          position: { lat: 47.282423, lng: 5.137297 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 293,
        },
        {
          NOM: "DESJOUIS MESSAGERIE",
          PAYS: "FR",
          CP: "61400",
          VILLE: "MORTAGNE AU PERCHE",
          position: { lat: 48.534264, lng: 0.522698 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 365,
        },
        {
          NOM: "DESORMEAUX",
          PAYS: "FR",
          CP: "27930",
          VILLE: "GRAVIGNY",
          position: { lat: 49.054516, lng: 1.169796 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 333,
        },
        {
          NOM: "DIANCOFF ET FILS TRANSPORTS",
          PAYS: "FR",
          CP: "81580",
          VILLE: "CAMBOUNET SUR LE SOR",
          position: { lat: 43.57493, lng: 2.14449 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1784,
        },
        {
          NOM: "DOUMEN LOGISTIQUE SERVICES BEZIERS",
          PAYS: "FR",
          CP: "34500",
          VILLE: "BEZIERS",
          position: { lat: 43.349231, lng: 3.135704 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1603,
        },
        {
          NOM: "DTC INTERNATIONAL",
          PAYS: "FR",
          CP: "71290",
          VILLE: "CUISERY",
          position: { lat: 46.57201, lng: 4.99421 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 331,
        },
        {
          NOM: "DUC LIMOUSIN",
          PAYS: "FR",
          CP: "87000",
          VILLE: "LIMOGES",
          position: { lat: 45.806527, lng: 1.293837 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 92,
        },
        {
          NOM: "DUC LOGISTIQUE FLOIRAC",
          PAYS: "FR",
          CP: "33270",
          VILLE: "FLOIRAC",
          position: { lat: 44.828753, lng: -0.523204 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 844,
        },
        {
          NOM: "DUC LOGISTIQUE MONTELIMAR",
          PAYS: "FR",
          CP: "26200",
          VILLE: "MONTELIMAR",
          position: { lat: 44.583216, lng: 4.745364 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 507,
        },
        {
          NOM: "DUC LOGISTIQUE ROUBAIX",
          PAYS: "FR",
          CP: "59100",
          VILLE: "ROUBAIX",
          position: { lat: 50.690778, lng: 3.200478 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1087,
        },
        {
          NOM: "DUC LOGISTIQUE ST PIERRE DE CHANDIE",
          PAYS: "FR",
          CP: "69780",
          VILLE: "ST PIERRE DE CHANDIEU",
          position: { lat: 45.661138, lng: 5.004265 },
          DEPOSE: "N",
          REPRISE: "N",
          EPAL_TV: 0,
        },
        {
          NOM: "DUC LOGISTIQUE VILLEJUST",
          PAYS: "FR",
          CP: "91140",
          VILLE: "VILLEJUST",
          position: { lat: 48.683789, lng: 2.219683 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 150,
        },
        {
          NOM: "DUCROUX TRANSPORTS",
          PAYS: "FR",
          CP: "69830",
          VILLE: "ST GEORGES DE RENEINS",
          position: { lat: 46.056276, lng: 4.720604 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 85,
        },
        {
          NOM: "DUPAS LEBEDA TRANSPORTS",
          PAYS: "FR",
          CP: "59247",
          VILLE: "FECHAIN",
          position: { lat: 50.267176, lng: 3.222379 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1683,
        },
        {
          NOM: "DUPAS LEBEDA VENDIN",
          PAYS: "FR",
          CP: "62880",
          VILLE: "VENDIN LE VIEIL",
          position: { lat: 50.46682, lng: 2.83704 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 644,
        },
        {
          NOM: "DYLOVAN LOGISTIQUE",
          PAYS: "FR",
          CP: "16560",
          VILLE: "ANAIS",
          position: { lat: 45.770647, lng: 0.190267 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 213,
        },
        {
          NOM: "DYTRANS",
          PAYS: "FR",
          CP: "95190",
          VILLE: "GOUSSAINVILLE",
          position: { lat: 49.02164, lng: 2.45076 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 7,
        },
        {
          NOM: "EAGLE TRANS",
          PAYS: "FR",
          CP: "38070",
          VILLE: "ST QUENTIN FALLAVIER",
          position: { lat: 45.66365, lng: 5.09731 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 202,
        },
        {
          NOM: "ELLIPSE LOGISTIC (CPP)",
          PAYS: "FR",
          CP: "91070",
          VILLE: "BONDOUFLE",
          position: { lat: 48.60642, lng: 2.36512 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "EM LOG",
          PAYS: "FR",
          CP: "76430",
          VILLE: "ST VIGOR D YMONVILLE",
          position: { lat: 49.47538, lng: 0.32317 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 7,
        },
        {
          NOM: "EMB PALETTES SAS",
          PAYS: "FR",
          CP: "03600",
          VILLE: "COMMENTRY",
          position: { lat: 46.30096, lng: 2.74435 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 977,
        },
        {
          NOM: "ENTREPOT LOGISTIQUE SARRION",
          PAYS: "FR",
          CP: "17000",
          VILLE: "LA ROCHELLE",
          position: { lat: 46.153795, lng: -1.202581 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 3,
        },
        {
          NOM: "ENTREPOT TAFTRANS",
          PAYS: "BE",
          CP: "7500",
          VILLE: "TOURNAI",
          position: { lat: 50.6159, lng: 3.3456 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 349,
        },
        {
          NOM: "ENVIRIS PACA - TSP 13",
          PAYS: "FR",
          CP: "13310",
          VILLE: "ST MARTIN DE CRAU",
          position: { lat: 43.519969, lng: 4.850562 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1517,
        },
        {
          NOM: "ENVIRIS RHONE ALPES - TE 69",
          PAYS: "FR",
          CP: "69700",
          VILLE: "LOIRE SUR RHONE",
          position: { lat: 45.561856, lng: 4.813454 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 127,
        },
        {
          NOM: "EPINAL EXPRESS",
          PAYS: "FR",
          CP: "88150",
          VILLE: "THAON LES VOSGES",
          position: { lat: 48.255826, lng: 6.400194 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 453,
        },
        {
          NOM: "ESL (STFV)",
          PAYS: "FR",
          CP: "47300",
          VILLE: "VILLENEUVE SUR LOT",
          position: { lat: 44.392685, lng: 0.753117 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 51,
        },
        {
          NOM: "EXPRESS CATALAN PS",
          PAYS: "FR",
          CP: "66600",
          VILLE: "RIVESALTES",
          position: { lat: 42.78525, lng: 2.89947 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 1517,
        },
        {
          NOM: "EYCHENNE TRANSPORTS ET LOGISTIQUE",
          PAYS: "FR",
          CP: "31830",
          VILLE: "PLAISANCE DU TOUCH",
          position: { lat: 43.594461, lng: 1.293177 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 118,
        },
        {
          NOM: "FABRICE MOISY TRANSPORTS",
          PAYS: "FR",
          CP: "37270",
          VILLE: "MONTLOUIS SUR LOIRE",
          position: { lat: 47.394953, lng: 0.802024 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 157,
        },
        {
          NOM: "FERLAY TRANSPORTS",
          PAYS: "FR",
          CP: "74150",
          VILLE: "RUMILLY",
          position: { lat: 45.83737, lng: 5.953861 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 93,
        },
        {
          NOM: "FLANDRES PROVENCE",
          PAYS: "FR",
          CP: "59850",
          VILLE: "NIEPPE",
          position: { lat: 50.686945, lng: 2.846183 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 765,
        },
        {
          NOM: "FRANCK JUNG TRANSPORTS VAL DE LOIRE",
          PAYS: "FR",
          CP: "37390",
          VILLE: "CHANCEAUX SUR CHOISILLE",
          position: { lat: 47.46671, lng: 0.74509 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 350,
        },
        {
          NOM: "FRANCK VIGUIE",
          PAYS: "FR",
          CP: "12200",
          VILLE: "VILLEFRANCHE DE ROUERGUE",
          position: { lat: 44.362417, lng: 2.004076 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 315,
        },
        {
          NOM: "GALINIER TRANSPORTS",
          PAYS: "FR",
          CP: "81260",
          VILLE: "LE BEZ",
          position: { lat: 43.631085, lng: 2.426589 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 940,
        },
        {
          NOM: "GARIOU",
          PAYS: "FR",
          CP: "85260",
          VILLE: "LES BROUZILS",
          position: { lat: 46.88902, lng: -1.32487 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "GAUTHIER TRANSPORTS",
          PAYS: "FR",
          CP: "24470",
          VILLE: "ST PARDOUX LA RIVIERE",
          position: { lat: 45.488984, lng: 0.744581 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 227,
        },
        {
          NOM: "GAUTIER FRET SOLUTIONS - GFS",
          PAYS: "FR",
          CP: "29170",
          VILLE: "ST EVARZEC",
          position: { lat: 47.97358, lng: -4.00273 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 32,
        },
        {
          NOM: "GENDRON TRANSPORTS",
          PAYS: "FR",
          CP: "86170",
          VILLE: "CISSE",
          position: { lat: 46.653381, lng: 0.280061 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 162,
        },
        {
          NOM: "GENIN LOGISTIQUE 08",
          PAYS: "FR",
          CP: "08330",
          VILLE: "VRIGNE AUX BOIS",
          position: { lat: 49.729635, lng: 4.842251 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 84,
        },
        {
          NOM: "GENIN LOGISTIQUE 51",
          PAYS: "FR",
          CP: "51100",
          VILLE: "REIMS",
          position: { lat: 49.2241, lng: 4.104195 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 561,
        },
        {
          NOM: "GEORGES BERNARD",
          PAYS: "FR",
          CP: "26270",
          VILLE: "LORIOL SUR DROME",
          position: { lat: 44.750439, lng: 4.795501 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 702,
        },
        {
          NOM: "GIRAUD LOGISTIQUE - ZONE LONGUE",
          PAYS: "FR",
          CP: "69400",
          VILLE: "VILLEFRANCHE SUR SAONE",
          position: { lat: 46.00738, lng: 4.72642 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 738,
        },
        {
          NOM: "GLACHANT TRANSPORTS",
          PAYS: "FR",
          CP: "80100",
          VILLE: "ABBEVILLE",
          position: { lat: 50.11494, lng: 1.86425 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1356,
        },
        {
          NOM: "GORRON FRET",
          PAYS: "FR",
          CP: "53190",
          VILLE: "DESERTINES",
          position: { lat: 48.472889, lng: -0.866262 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 10,
        },
        {
          NOM: "GRIMAUD 85 PSC",
          PAYS: "FR",
          CP: "85200",
          VILLE: "FONTENAY LE COMTE",
          position: { lat: 46.454072, lng: -0.778553 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "GUIVARC'H TRANSPORTS",
          PAYS: "FR",
          CP: "29400",
          VILLE: "BODILIS",
          position: { lat: 48.51563, lng: -4.113562 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 3,
        },
        {
          NOM: "GUYAMIER",
          PAYS: "FR",
          CP: "33810",
          VILLE: "AMBES",
          position: { lat: 45.020126, lng: -0.575605 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 448,
        },
        {
          NOM: "HAUTIERE TRANSPORTS",
          PAYS: "FR",
          CP: "35440",
          VILLE: "MONTREUIL SUR ILLE",
          position: { lat: 48.302418, lng: -1.676347 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 44,
        },
        {
          NOM: "HAZOTTE TRANSPORTS",
          PAYS: "FR",
          CP: "54119",
          VILLE: "DOMGERMAIN",
          position: { lat: 48.64431, lng: 5.83796 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 821,
        },
        {
          NOM: "HEILMANN TRANSPORTS",
          PAYS: "FR",
          CP: "88390",
          VILLE: "DARNIEULLES",
          position: { lat: 48.198021, lng: 6.339781 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 510,
        },
        {
          NOM: "HEINTZ TRANSPORT",
          PAYS: "FR",
          CP: "57500",
          VILLE: "ST AVOLD",
          position: { lat: 49.141675, lng: 6.694759 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 1009,
        },
        {
          NOM: "HEMMERLIN",
          PAYS: "FR",
          CP: "68390",
          VILLE: "SAUSHEIM",
          position: { lat: 47.78249, lng: 7.38573 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 247,
        },
        {
          NOM: "HEPPNER HENRIVILLE",
          PAYS: "FR",
          CP: "57450",
          VILLE: "HENRIVILLE",
          position: { lat: 49.10117, lng: 6.86863 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 274,
        },
        {
          NOM: "HLBG",
          PAYS: "FR",
          CP: "14600",
          VILLE: "HONFLEUR",
          position: { lat: 49.393631, lng: 0.254309 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 93,
        },
        {
          NOM: "HOLDING HEBTING",
          PAYS: "FR",
          CP: "67410",
          VILLE: "DRUSENHEIM",
          position: { lat: 48.753487, lng: 7.939293 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 2873,
        },
        {
          NOM: "HOUDRAY TRANSPORTS",
          PAYS: "FR",
          CP: "18230",
          VILLE: "ST DOULCHARD",
          position: { lat: 47.093798, lng: 2.387293 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 953,
        },
        {
          NOM: "IENN TRANSPORTS SAS",
          PAYS: "FR",
          CP: "25600",
          VILLE: "VIEUX CHARMONT",
          position: { lat: 47.52273, lng: 6.84262 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 215,
        },
        {
          NOM: "IMANY",
          PAYS: "FR",
          CP: "52200",
          VILLE: "ST GEOSMES",
          position: { lat: 47.82914, lng: 5.33042 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 271,
        },
        {
          NOM: "INTEGRE LOGISTICS",
          PAYS: "FR",
          CP: "78680",
          VILLE: "EPONE",
          position: { lat: 48.96147, lng: 1.81472 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 336,
        },
        {
          NOM: "JEANTET",
          PAYS: "FR",
          CP: "25770",
          VILLE: "VAUX LES PRES",
          position: { lat: 47.232416, lng: 5.893368 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "JF PAQUET TRANSPORTS",
          PAYS: "FR",
          CP: "88140",
          VILLE: "CONTREXEVILLE",
          position: { lat: 48.190427, lng: 5.883064 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1088,
        },
        {
          NOM: "JLG SERVICE  LA CRECHE",
          PAYS: "FR",
          CP: "79260",
          VILLE: "LA CRECHE",
          position: { lat: 46.34914, lng: -0.296771 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "JLG SERVICES 35",
          PAYS: "FR",
          CP: "35530",
          VILLE: "NOYAL SUR VILAINE",
          position: { lat: 48.101995, lng: -1.512147 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "JLG SERVICES NARBONNE",
          PAYS: "FR",
          CP: "11100",
          VILLE: "NARBONNE",
          position: { lat: 43.159346, lng: 2.988665 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "JOLIVAL",
          PAYS: "FR",
          CP: "49620",
          VILLE: "LA POMMERAYE",
          position: { lat: 47.348167, lng: -0.854972 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 3,
        },
        {
          NOM: "JOURDAN TRANSPORTS SAS",
          PAYS: "FR",
          CP: "50600",
          VILLE: "ST HILAIRE DU HARCOUET",
          position: { lat: 48.566296, lng: -1.073424 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 28,
        },
        {
          NOM: "JULLIEN TRANSPORT",
          PAYS: "FR",
          CP: "26350",
          VILLE: "CREPOL",
          position: { lat: 45.166382, lng: 5.065231 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 472,
        },
        {
          NOM: "KIMMEL LOGISTIK",
          PAYS: "FR",
          CP: "67320",
          VILLE: "DRULINGEN",
          position: { lat: 48.91911, lng: 7.12741 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 3,
        },
        {
          NOM: "KTI - TRANSPORTS KTI KLEIN",
          PAYS: "FR",
          CP: "67116",
          VILLE: "REICHSTETT",
          position: { lat: 48.642318, lng: 7.77234 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1480,
        },
        {
          NOM: "LA FLECHE ARDENNAISE PAUVRES",
          PAYS: "FR",
          CP: "08310",
          VILLE: "PAUVRES",
          position: { lat: 49.4112, lng: 4.49154 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 4,
        },
        {
          NOM: "LACHAUD TRANSPORTS",
          PAYS: "FR",
          CP: "24600",
          VILLE: "VILLETOUREIX",
          position: { lat: 45.26217, lng: 0.34002 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 68,
        },
        {
          NOM: "LAMBERT ET FILS BACCARAT TRANSPORTS",
          PAYS: "FR",
          CP: "54120",
          VILLE: "BACCARAT",
          position: { lat: 48.445181, lng: 6.722366 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 304,
        },
        {
          NOM: "LANDES STOCKAGES",
          PAYS: "FR",
          CP: "40260",
          VILLE: "CASTETS",
          position: { lat: 43.873404, lng: -1.142247 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 592,
        },
        {
          NOM: "LANDRY",
          PAYS: "FR",
          CP: "79100",
          VILLE: "THOUARS",
          position: { lat: 46.993118, lng: -0.191541 },
          DEPOSE: "N",
          REPRISE: "N",
          EPAL_TV: 0,
        },
        {
          NOM: "LAPORTE RENE ET FILS",
          PAYS: "FR",
          CP: "64460",
          VILLE: "PONSON DESSUS",
          position: { lat: 43.317492, lng: -0.056837 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 108,
        },
        {
          NOM: "LAVAL FRERES",
          PAYS: "FR",
          CP: "33220",
          VILLE: "PINEUILH",
          position: { lat: 44.84165, lng: 0.246227 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 11,
        },
        {
          NOM: "LE GUEVEL  MINIAC",
          PAYS: "FR",
          CP: "35540",
          VILLE: "MINIAC MORVAN",
          position: { lat: 48.542884, lng: -1.912702 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 98,
        },
        {
          NOM: "LE GUEVEL LA GRAVELLE",
          PAYS: "FR",
          CP: "53410",
          VILLE: "LA GRAVELLE",
          position: { lat: 48.080436, lng: -1.024516 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 56,
        },
        {
          NOM: "LEBLANC TRANSPORTS",
          PAYS: "FR",
          CP: "70100",
          VILLE: "AUTREY LES GRAY",
          position: { lat: 47.48502, lng: 5.49039 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 143,
        },
        {
          NOM: "LECAMUS",
          PAYS: "FR",
          CP: "14100",
          VILLE: "ST DESIR",
          position: { lat: 49.139404, lng: 0.186565 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 47,
        },
        {
          NOM: "LEROUX",
          PAYS: "FR",
          CP: "87220",
          VILLE: "BOISSEUIL",
          position: { lat: 45.76083, lng: 1.333021 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 91,
        },
        {
          NOM: "LES MESSAGERIES JURASSIENNES",
          PAYS: "FR",
          CP: "39800",
          VILLE: "POLIGNY",
          position: { lat: 46.8456, lng: 5.69105 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 275,
        },
        {
          NOM: "LEVASSEUR TRANSPORT SERVICE",
          PAYS: "FR",
          CP: "16560",
          VILLE: "TOURRIERS",
          position: { lat: 45.803847, lng: 0.193232 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 179,
        },
        {
          NOM: "LHERITIER",
          PAYS: "FR",
          CP: "15130",
          VILLE: "SANSAC DE MARMIESSE",
          position: { lat: 44.884026, lng: 2.374802 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 82,
        },
        {
          NOM: "LOG HF",
          PAYS: "FR",
          CP: "35550",
          VILLE: "PIPRIAC",
          position: { lat: 47.80731, lng: -1.92948 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 615,
        },
        {
          NOM: "LOGILOR",
          PAYS: "FR",
          CP: "44360",
          VILLE: "ST ETIENNE DE MONTLUC",
          position: { lat: 47.28436, lng: -1.74361 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 273,
        },
        {
          NOM: "LOGISTIQUE SF",
          PAYS: "FR",
          CP: "44590",
          VILLE: "DERVAL",
          position: { lat: 47.637804, lng: -1.653803 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 180,
        },
        {
          NOM: "MAILLARD TRANSPORTS",
          PAYS: "FR",
          CP: "44160",
          VILLE: "BESNE",
          position: { lat: 47.406888, lng: -2.088431 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 97,
        },
        {
          NOM: "MANDICO",
          PAYS: "FR",
          CP: "82170",
          VILLE: "GRISOLLES",
          position: { lat: 43.839282, lng: 1.289469 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1592,
        },
        {
          NOM: "MAZET AFFRETEMENT PERPIGNAN",
          PAYS: "FR",
          CP: "66600",
          VILLE: "RIVESALTES",
          position: { lat: 42.77737, lng: 2.89844 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 2330,
        },
        {
          NOM: "MERLYONE GROUP 45",
          PAYS: "FR",
          CP: "45140",
          VILLE: "ORMES",
          position: { lat: 47.94656, lng: 1.82935 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 362,
        },
        {
          NOM: "MERLYONE GROUP 77",
          PAYS: "FR",
          CP: "77550",
          VILLE: "MOISSY CRAMAYEL",
          position: { lat: 48.62373, lng: 2.56455 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 3545,
        },
        {
          NOM: "MERMET JC TRANSPORTS",
          PAYS: "FR",
          CP: "74460",
          VILLE: "MARNAZ",
          position: { lat: 46.072809, lng: 6.52589 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 217,
        },
        {
          NOM: "MESGUEN TRANSPORTS 29",
          PAYS: "FR",
          CP: "29250",
          VILLE: "ST POL DE LEON",
          position: { lat: 48.668607, lng: -4.002959 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 21,
        },
        {
          NOM: "MESGUEN TRANSPORTS CHERBOURG 50",
          PAYS: "FR",
          CP: "50110",
          VILLE: "TOURLAVILLE",
          position: { lat: 49.650846, lng: -1.584942 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "MICHAUX LOGISTIQUE",
          PAYS: "FR",
          CP: "08350",
          VILLE: "DONCHERY",
          position: { lat: 49.707183, lng: 4.882325 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 353,
        },
        {
          NOM: "MONTAVILLE",
          PAYS: "FR",
          CP: "72140",
          VILLE: "SILLE LE GUILLAUME",
          position: { lat: 48.173572, lng: -0.142801 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 42,
        },
        {
          NOM: "MORIN TRANSPORTS",
          PAYS: "FR",
          CP: "56140",
          VILLE: "TREAL",
          position: { lat: 47.832825, lng: -2.232792 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 7,
        },
        {
          NOM: "MT LOGISTICS",
          PAYS: "FR",
          CP: "63360",
          VILLE: "GERZAT",
          position: { lat: 45.831316, lng: 3.127635 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 131,
        },
        {
          NOM: "MULTILOX",
          PAYS: "FR",
          CP: "71240",
          VILLE: "SENNECEY LE GRAND",
          position: { lat: 46.632816, lng: 4.878745 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 2248,
        },
        {
          NOM: "NESSUS TRANSPORTS",
          PAYS: "FR",
          CP: "54180",
          VILLE: "HEILLECOURT",
          position: { lat: 48.6484, lng: 6.2111 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 285,
        },
        {
          NOM: "NICOLLE",
          PAYS: "FR",
          CP: "14123",
          VILLE: "IFS",
          position: { lat: 49.144298, lng: -0.32568 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "NONNOTTE TRANSPORTS",
          PAYS: "FR",
          CP: "39700",
          VILLE: "DAMPIERRE",
          position: { lat: 47.15956, lng: 5.76117 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 19,
        },
        {
          NOM: "NOUGAREDE TRANSPORTS",
          PAYS: "FR",
          CP: "82200",
          VILLE: "MOISSAC",
          position: { lat: 44.11319, lng: 1.11457 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 565,
        },
        {
          NOM: "NRS PS",
          PAYS: "FR",
          CP: "62138",
          VILLE: "DOUVRIN",
          position: { lat: 50.512161, lng: 2.83888 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 1203,
        },
        {
          NOM: "OPTI SOLUTIONS",
          PAYS: "FR",
          CP: "84000",
          VILLE: "AVIGNON",
          position: { lat: 43.93967, lng: 4.792042 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 1334,
        },
        {
          NOM: "OT LOGISTIQUE",
          PAYS: "FR",
          CP: "04200",
          VILLE: "SISTERON",
          position: { lat: 44.228241, lng: 5.914502 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 2263,
        },
        {
          NOM: "OTL SERVICES",
          PAYS: "FR",
          CP: "54420",
          VILLE: "PULNOY",
          position: { lat: 48.70595, lng: 6.25326 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 720,
        },
        {
          NOM: "PARIS TRACTION",
          PAYS: "FR",
          CP: "92230",
          VILLE: "GENNEVILLIERS",
          position: { lat: 48.93778, lng: 2.276804 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 484,
        },
        {
          NOM: "PARTENAIRES REGIONS TRANSPORTS",
          PAYS: "FR",
          CP: "85320",
          VILLE: "MAREUIL SUR LAY DISSAIS",
          position: { lat: 46.53693, lng: -1.23051 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 7,
        },
        {
          NOM: "PAUL LIBBRA DISTRIBUTION",
          PAYS: "FR",
          CP: "22400",
          VILLE: "LAMBALLE",
          position: { lat: 48.454252, lng: -2.496372 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "PAUL LIBBRA INDUSTRIE PLI",
          PAYS: "FR",
          CP: "21470",
          VILLE: "BRAZEY EN PLAINE",
          position: { lat: 47.126888, lng: 5.212717 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "PAUL LIBBRA LOGISTIQUE ALSACE -PLLA",
          PAYS: "FR",
          CP: "68000",
          VILLE: "COLMAR",
          position: { lat: 48.106767, lng: 7.380333 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 346,
        },
        {
          NOM: "PAUL LIBBRA LOGISTIQUE YONNE",
          PAYS: "FR",
          CP: "89150",
          VILLE: "DOMATS",
          position: { lat: 48.11876, lng: 3.06033 },
          DEPOSE: "N",
          REPRISE: "N",
          EPAL_TV: 0,
        },
        {
          NOM: "PEBERAT",
          PAYS: "FR",
          CP: "47600",
          VILLE: "NERAC",
          position: { lat: 44.162454, lng: 0.304425 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 324,
        },
        {
          NOM: "PEDUSSAUT TRANSPORTS",
          PAYS: "FR",
          CP: "31390",
          VILLE: "CARBONNE",
          position: { lat: 43.29094, lng: 1.20034 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 989,
        },
        {
          NOM: "PEIXOTO BORDEAUX GIRONDE",
          PAYS: "FR",
          CP: "33750",
          VILLE: "BEYCHAC ET CAILLAU",
          position: { lat: 44.88058, lng: -0.368559 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 177,
        },
        {
          NOM: "PEROCHEAU",
          PAYS: "FR",
          CP: "85670",
          VILLE: "ST CHRISTOPHE DU LIGNERON",
          position: { lat: 46.819122, lng: -1.766969 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "PERRENOT BEKAERT",
          PAYS: "FR",
          CP: "62223",
          VILLE: "ATHIES",
          position: { lat: 50.320166, lng: 2.844239 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1848,
        },
        {
          NOM: "PERRENOT BUCHACA",
          PAYS: "FR",
          CP: "34500",
          VILLE: "BEZIERS",
          position: { lat: 43.341784, lng: 3.285978 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 1352,
        },
        {
          NOM: "PERRENOT DENAIN",
          PAYS: "FR",
          CP: "59220",
          VILLE: "DENAIN",
          position: { lat: 50.336749, lng: 3.388626 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1434,
        },
        {
          NOM: "PERRENOT ESCRENNES",
          PAYS: "FR",
          CP: "45300",
          VILLE: "ESCRENNES",
          position: { lat: 48.12407, lng: 2.19882 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 933,
        },
        {
          NOM: "PERRENOT GASCOGNE",
          PAYS: "FR",
          CP: "40230",
          VILLE: "BENESSE MAREMNE",
          position: { lat: 43.629753, lng: -1.392747 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1605,
        },
        {
          NOM: "PERRENOT HERSAND",
          PAYS: "FR",
          CP: "86440",
          VILLE: "MIGNE AUXANCES",
          position: { lat: 46.612951, lng: 0.310828 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 340,
        },
        {
          NOM: "PERRENOT NORMANDIE",
          PAYS: "FR",
          CP: "27400",
          VILLE: "HEUDEBOUVILLE",
          position: { lat: 49.194031, lng: 1.226345 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "PERRENOT RENNES",
          PAYS: "FR",
          CP: "35290",
          VILLE: "GAEL",
          position: { lat: 48.127252, lng: -2.229372 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 85,
        },
        {
          NOM: "PERRENOT ROBINEAU",
          PAYS: "FR",
          CP: "72370",
          VILLE: "SOULITRE",
          position: { lat: 48.036149, lng: 0.426589 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 426,
        },
        {
          NOM: "PERRENOT ROUEN",
          PAYS: "FR",
          CP: "76120",
          VILLE: "LE GRAND QUEVILLY",
          position: { lat: 49.422141, lng: 1.038912 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 173,
        },
        {
          NOM: "PERRENOT SYNERGIES",
          PAYS: "FR",
          CP: "69470",
          VILLE: "COURS LA VILLE",
          position: { lat: 46.10013, lng: 4.31668 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 210,
        },
        {
          NOM: "PERRENOT TRANSVALLEES",
          PAYS: "FR",
          CP: "88170",
          VILLE: "CHATENOIS",
          position: { lat: 48.30192, lng: 5.85087 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 419,
        },
        {
          NOM: "PERRIER TRANSPORTS",
          PAYS: "FR",
          CP: "39570",
          VILLE: "COURLAOUX",
          position: { lat: 46.668893, lng: 5.422934 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 211,
        },
        {
          NOM: "PHCTRANSPORTS  PHILIPPE CAZAUBON",
          PAYS: "FR",
          CP: "40090",
          VILLE: "ST PERDON",
          position: { lat: 43.876526, lng: -0.563371 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 440,
        },
        {
          NOM: "PICQ ET CHARBONNIER",
          PAYS: "FR",
          CP: "89470",
          VILLE: "MONETEAU",
          position: { lat: 47.831007, lng: 3.57702 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1266,
        },
        {
          NOM: "PICQ ET CHARBONNIER VALLOUX",
          PAYS: "FR",
          CP: "89200",
          VILLE: "VAULT DE LUGNY",
          position: { lat: 47.50912, lng: 3.84727 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 359,
        },
        {
          NOM: "PIHEN LOGISTIQUE SARL",
          PAYS: "FR",
          CP: "60190",
          VILLE: "REMY",
          position: { lat: 49.427705, lng: 2.692777 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 498,
        },
        {
          NOM: "PIHEN RHONE ALPES",
          PAYS: "FR",
          CP: "42120",
          VILLE: "LE COTEAU",
          position: { lat: 46.008508, lng: 4.09914 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 134,
        },
        {
          NOM: "PLF INTERNATIONAL",
          PAYS: "FR",
          CP: "77380",
          VILLE: "COMBS LA VILLE",
          position: { lat: 48.654666, lng: 2.554168 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 1140,
        },
        {
          NOM: "PLS TRANSPORTS",
          PAYS: "FR",
          CP: "01160",
          VILLE: "ST MARTIN DU MONT",
          position: { lat: 46.09216, lng: 5.31197 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1590,
        },
        {
          NOM: "PLUVIAUD TPO AEM",
          PAYS: "FR",
          CP: "36130",
          VILLE: "DEOLS",
          position: { lat: 46.844825, lng: 1.707198 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 962,
        },
        {
          NOM: "POULAIN TRANSPORTS",
          PAYS: "FR",
          CP: "56850",
          VILLE: "CAUDAN",
          position: { lat: 47.787668, lng: -3.350902 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "PROCASE SARL",
          PAYS: "FR",
          CP: "13560",
          VILLE: "SENAS",
          position: { lat: 43.74445, lng: 5.11051 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 447,
        },
        {
          NOM: "PROPAL 33",
          PAYS: "FR",
          CP: "33124",
          VILLE: "AILLAS",
          position: { lat: 44.5135, lng: -0.05376 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 174,
        },
        {
          NOM: "PROUHEZE PARADIS LOGISTIQUE",
          PAYS: "FR",
          CP: "12560",
          VILLE: "CAMPAGNAC",
          position: { lat: 44.384595, lng: 3.11342 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 150,
        },
        {
          NOM: "PROVENCE DISTRIBUTION LOGISTIQUE",
          PAYS: "FR",
          CP: "83170",
          VILLE: "BRIGNOLES",
          position: { lat: 43.395464, lng: 6.15991 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1078,
        },
        {
          NOM: "RALLU TRANSPORTS",
          PAYS: "FR",
          CP: "35133",
          VILLE: "LA SELLE EN LUITRE",
          position: { lat: 48.33369, lng: -1.16498 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 83,
        },
        {
          NOM: "REGIONAL EXPRESS MAUREPAS",
          PAYS: "FR",
          CP: "78310",
          VILLE: "MAUREPAS",
          position: { lat: 48.760114, lng: 1.932428 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 521,
        },
        {
          NOM: "RIBEYRE TRANSPORTS",
          PAYS: "FR",
          CP: "26500",
          VILLE: "BOURG LES VALENCE",
          position: { lat: 44.963892, lng: 4.882397 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 256,
        },
        {
          NOM: "ROAD FROID",
          PAYS: "FR",
          CP: "82000",
          VILLE: "MONTAUBAN",
          position: { lat: 44.03219, lng: 1.35105 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 808,
        },
        {
          NOM: "ROMARY TRANSPORTS",
          PAYS: "FR",
          CP: "70220",
          VILLE: "FOUGEROLLES",
          position: { lat: 47.86697, lng: 6.38382 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 415,
        },
        {
          NOM: "ROSETTE TRANSPORTS",
          PAYS: "FR",
          CP: "49680",
          VILLE: "NEUILLE",
          position: { lat: 47.310899, lng: -0.021192 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 402,
        },
        {
          NOM: "ROUILLE COULON",
          PAYS: "FR",
          CP: "34160",
          VILLE: "BOISSERON",
          position: { lat: 43.750346, lng: 4.089494 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1401,
        },
        {
          NOM: "ROUILLON",
          PAYS: "FR",
          CP: "88200",
          VILLE: "ST NABORD",
          position: { lat: 48.017147, lng: 6.561069 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 161,
        },
        {
          NOM: "ROUSSEAU",
          PAYS: "FR",
          CP: "58460",
          VILLE: "CORVOL L ORGUEILLEUX",
          position: { lat: 47.432373, lng: 3.411919 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 319,
        },
        {
          NOM: "ROUSSEAU AQUITAINE",
          PAYS: "FR",
          CP: "33910",
          VILLE: "ST DENIS DE PILE",
          position: { lat: 44.97173, lng: -0.16539 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "ROUXEL LOGISTIQUE",
          PAYS: "FR",
          CP: "56890",
          VILLE: "ST AVE",
          position: { lat: 47.67554, lng: -2.727945 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1,
        },
        {
          NOM: "S.A.T.",
          PAYS: "FR",
          CP: "07250",
          VILLE: "LE POUZIN",
          position: { lat: 44.744469, lng: 4.752719 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 59,
        },
        {
          NOM: "S.T.E.M.06",
          PAYS: "FR",
          CP: "06670",
          VILLE: "COLOMARS",
          position: { lat: 43.764391, lng: 7.22045 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1047,
        },
        {
          NOM: "SADE LOGISTIQUES SA",
          PAYS: "FR",
          CP: "57270",
          VILLE: "RICHEMONT",
          position: { lat: 49.274874, lng: 6.169145 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 69,
        },
        {
          NOM: "SAMTRANS",
          PAYS: "FR",
          CP: "33870",
          VILLE: "VAYRES",
          position: { lat: 44.88087, lng: -0.34228 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 253,
        },
        {
          NOM: "SARL GF2S",
          PAYS: "FR",
          CP: "73290",
          VILLE: "LA MOTTE SERVOLEX",
          position: { lat: 45.60247, lng: 5.8813 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 44,
        },
        {
          NOM: "SARL LOSANE",
          PAYS: "FR",
          CP: "60280",
          VILLE: "CLAIROIX",
          position: { lat: 49.43575, lng: 2.83809 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 158,
        },
        {
          NOM: "SARL PALETTES 58",
          PAYS: "FR",
          CP: "58000",
          VILLE: "ST ELOI",
          position: { lat: 46.96744, lng: 3.22783 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 2874,
        },
        {
          NOM: "SARL ROCHET 45",
          PAYS: "FR",
          CP: "45410",
          VILLE: "ARTENAY",
          position: { lat: 48.07634, lng: 1.87856 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 507,
        },
        {
          NOM: "SARL TRANSPORTS CEP EXTRAS TCE",
          PAYS: "FR",
          CP: "51160",
          VILLE: "AY",
          position: { lat: 49.05056, lng: 4.00551 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 572,
        },
        {
          NOM: "SARL TRANSPORTS VANHOVE",
          PAYS: "FR",
          CP: "88230",
          VILLE: "FRAIZE",
          position: { lat: 48.181618, lng: 7.011667 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 317,
        },
        {
          NOM: "SARRE MOSELLE LOGISTIQUE",
          PAYS: "FR",
          CP: "57210",
          VILLE: "MAIZIERES LES METZ",
          position: { lat: 49.205532, lng: 6.151701 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "SAS VOULAND FRERES",
          PAYS: "FR",
          CP: "13750",
          VILLE: "PLAN D ORGON",
          position: { lat: 43.82468, lng: 5.02447 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1008,
        },
        {
          NOM: "SAVOIE MESSAGERIE EXPRESS",
          PAYS: "FR",
          CP: "73460",
          VILLE: "STE HELENE SUR ISERE",
          position: { lat: 45.61394, lng: 6.30589 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 174,
        },
        {
          NOM: "SCS TRANSPORTS",
          PAYS: "FR",
          CP: "31380",
          VILLE: "GARIDECH",
          position: { lat: 43.71884, lng: 1.56986 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 2415,
        },
        {
          NOM: "SEILLERY",
          PAYS: "FR",
          CP: "49124",
          VILLE: "ST BARTHELEMY D ANJOU",
          position: { lat: 47.476495, lng: -0.520399 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 28,
        },
        {
          NOM: "SENYA STOCKGEL",
          PAYS: "FR",
          CP: "24100",
          VILLE: "BERGERAC",
          position: { lat: 44.85714, lng: 0.51587 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 84,
        },
        {
          NOM: "SERVICE LOGISTIQUE TRANSPORTS - SLT",
          PAYS: "FR",
          CP: "72350",
          VILLE: "BRULON",
          position: { lat: 47.97629, lng: -0.232794 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 58,
        },
        {
          NOM: "SETAK",
          PAYS: "FR",
          CP: "09100",
          VILLE: "PAMIERS",
          position: { lat: 43.12923, lng: 1.626093 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 7,
        },
        {
          NOM: "SETEL BASSENS",
          PAYS: "FR",
          CP: "33530",
          VILLE: "BASSENS",
          position: { lat: 44.92182, lng: -0.53828 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 2,
        },
        {
          NOM: "SETEL CAVAILLON",
          PAYS: "FR",
          CP: "84300",
          VILLE: "CAVAILLON",
          position: { lat: 43.81542, lng: 5.04668 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 1926,
        },
        {
          NOM: "SFT TRANSPORTS",
          PAYS: "FR",
          CP: "77000",
          VILLE: "VAUX LE PENIL",
          position: { lat: 48.53385, lng: 2.690466 },
          DEPOSE: "N",
          REPRISE: "N",
          EPAL_TV: 0,
        },
        {
          NOM: "SITRANS LOGISTIQUE",
          PAYS: "FR",
          CP: "28630",
          VILLE: "GELLAINVILLE",
          position: { lat: 48.434792, lng: 1.523903 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 309,
        },
        {
          NOM: "SO FRET",
          PAYS: "FR",
          CP: "48200",
          VILLE: "ST CHELY D APCHER",
          position: { lat: 44.80995, lng: 3.28407 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 20,
        },
        {
          NOM: "SOBOLUX - ZIEGLER",
          PAYS: "LU",
          CP: "3451",
          VILLE: "DUDELANGE",
          position: { lat: 49.49806, lng: 6.11309 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 362,
        },
        {
          NOM: "SOCIETE TRANSPORTS NORD SUR INDRE",
          PAYS: "FR",
          CP: "36210",
          VILLE: "VARENNES SUR FOUZON",
          position: { lat: 47.207408, lng: 1.605792 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 25,
        },
        {
          NOM: "SODEL",
          PAYS: "FR",
          CP: "55190",
          VILLE: "PAGNY SUR MEUSE",
          position: { lat: 48.68635, lng: 5.740604 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1438,
        },
        {
          NOM: "SOGRANLOTRANS",
          PAYS: "FR",
          CP: "42650",
          VILLE: "ST JEAN BONNEFONDS",
          position: { lat: 45.464971, lng: 4.42792 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 253,
        },
        {
          NOM: "SOTRA D'OC",
          PAYS: "FR",
          CP: "11100",
          VILLE: "NARBONNE",
          position: { lat: 43.17455, lng: 2.97379 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 588,
        },
        {
          NOM: "SOTRAMAN",
          PAYS: "FR",
          CP: "25110",
          VILLE: "AUTECHAUX",
          position: { lat: 47.38048, lng: 6.37737 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 185,
        },
        {
          NOM: "SOTRIME",
          PAYS: "FR",
          CP: "93600",
          VILLE: "AULNAY SOUS BOIS",
          position: { lat: 48.95849, lng: 2.470672 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 90,
        },
        {
          NOM: "SPECILOR",
          PAYS: "FR",
          CP: "57210",
          VILLE: "HAUCONCOURT",
          position: { lat: 49.203241, lng: 6.190554 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1023,
        },
        {
          NOM: "STE NOUVELLE SAVEL",
          PAYS: "FR",
          CP: "69120",
          VILLE: "VAULX EN VELIN",
          position: { lat: 45.778088, lng: 4.940861 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 509,
        },
        {
          NOM: "STEFANSKI TRANSPORTS",
          PAYS: "FR",
          CP: "45700",
          VILLE: "PANNES",
          position: { lat: 47.9877, lng: 2.66951 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1405,
        },
        {
          NOM: "STIC",
          PAYS: "FR",
          CP: "16440",
          VILLE: "ROULLET ST ESTEPHE",
          position: { lat: 45.588651, lng: 0.014172 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 816,
        },
        {
          NOM: "STIC SUD",
          PAYS: "FR",
          CP: "30000",
          VILLE: "NIMES",
          position: { lat: 43.805699, lng: 4.320418 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 563,
        },
        {
          NOM: "STM",
          PAYS: "FR",
          CP: "17400",
          VILLE: "ST JEAN D ANGELY",
          position: { lat: 45.930143, lng: -0.5272 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 79,
        },
        {
          NOM: "STOCKEDIS PLUS",
          PAYS: "FR",
          CP: "57200",
          VILLE: "SARREGUEMINES",
          position: { lat: 49.10325, lng: 7.10022 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 665,
        },
        {
          NOM: "STR 51",
          PAYS: "FR",
          CP: "51520",
          VILLE: "ST MARTIN SUR LE PRE",
          position: { lat: 48.98104, lng: 4.3452 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 609,
        },
        {
          NOM: "STS EROBS",
          PAYS: "FR",
          CP: "57340",
          VILLE: "MORHANGE",
          position: { lat: 48.941168, lng: 6.653276 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 818,
        },
        {
          NOM: "TAGLANG",
          PAYS: "FR",
          CP: "67400",
          VILLE: "ILLKIRCH GRAFFENSTADEN",
          position: { lat: 48.514425, lng: 7.709339 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 2650,
        },
        {
          NOM: "TC 58 - CASSIER",
          PAYS: "FR",
          CP: "58340",
          VILLE: "CERCY LA TOUR",
          position: { lat: 46.853923, lng: 3.637116 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 411,
        },
        {
          NOM: "TCP DISTRIBUTION",
          PAYS: "FR",
          CP: "10120",
          VILLE: "ST ANDRE LES VERGERS",
          position: { lat: 48.26754, lng: 4.046459 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 499,
        },
        {
          NOM: "TCP SENS",
          PAYS: "FR",
          CP: "89100",
          VILLE: "SENS",
          position: { lat: 48.200862, lng: 3.317273 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 263,
        },
        {
          NOM: "TDF TRANSPORT ET LOGISTIQUE",
          PAYS: "FR",
          CP: "64700",
          VILLE: "HENDAYE",
          position: { lat: 43.344267, lng: -1.773823 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "TENART TRANSPORT EUROPE",
          PAYS: "FR",
          CP: "60930",
          VILLE: "BAILLEUL SUR THERAIN",
          position: { lat: 49.378364, lng: 2.23177 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 441,
        },
        {
          NOM: "TGG",
          PAYS: "FR",
          CP: "12340",
          VILLE: "BOZOULS",
          position: { lat: 44.476069, lng: 2.737181 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 856,
        },
        {
          NOM: "TGM",
          PAYS: "FR",
          CP: "06510",
          VILLE: "CARROS",
          position: { lat: 43.80397, lng: 7.18784 },
          DEPOSE: "N",
          REPRISE: "N",
          EPAL_TV: 0,
        },
        {
          NOM: "THEVENET TRANSPORTS SAS",
          PAYS: "FR",
          CP: "03150",
          VILLE: "ST LOUP",
          position: { lat: 46.359292, lng: 3.362434 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 280,
        },
        {
          NOM: "TLDM TRANSPORTS",
          PAYS: "FR",
          CP: "31150",
          VILLE: "LESPINASSE",
          position: { lat: 43.7278, lng: 1.386 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 2566,
        },
        {
          NOM: "TLM TRANSPORTS",
          PAYS: "FR",
          CP: "18100",
          VILLE: "VIERZON",
          position: { lat: 47.214915, lng: 2.107295 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 15,
        },
        {
          NOM: "TOUZE TRANSPORTS",
          PAYS: "FR",
          CP: "22200",
          VILLE: "PLOUISY",
          position: { lat: 48.577997, lng: -3.172661 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 33,
        },
        {
          NOM: "TRADA TRANSPORTS",
          PAYS: "FR",
          CP: "26800",
          VILLE: "PORTES LES VALENCE",
          position: { lat: 44.885625, lng: 4.863617 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 482,
        },
        {
          NOM: "TRADIBEAUCE LOGISTIQUE",
          PAYS: "FR",
          CP: "28600",
          VILLE: "LUISANT",
          position: { lat: 48.432371, lng: 1.46847 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 53,
        },
        {
          NOM: "TRANS BEARN",
          PAYS: "FR",
          CP: "64270",
          VILLE: "BELLOCQ",
          position: { lat: 43.521083, lng: -0.91723 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 871,
        },
        {
          NOM: "TRANS INTER GATINAIS",
          PAYS: "FR",
          CP: "79200",
          VILLE: "PARTHENAY",
          position: { lat: 46.644042, lng: -0.226863 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 199,
        },
        {
          NOM: "TRANSLANDES SERVICES",
          PAYS: "FR",
          CP: "40700",
          VILLE: "HAGETMAU",
          position: { lat: 43.64053, lng: -0.61419 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 41,
        },
        {
          NOM: "TRANSPORT A-S RAMART",
          PAYS: "BE",
          CP: "7160",
          VILLE: "CHAPELLE-LEZ-HERLAIMONT",
          position: { lat: 50.444012, lng: 4.278745 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 25,
        },
        {
          NOM: "TRANSPORT SCHMIDT",
          PAYS: "FR",
          CP: "67230",
          VILLE: "SERMERSHEIM",
          position: { lat: 48.346872, lng: 7.546428 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 531,
        },
        {
          NOM: "TRANSPORT THIERRY MERCIER",
          PAYS: "FR",
          CP: "01460",
          VILLE: "MONTREAL LA CLUSE",
          position: { lat: 46.176888, lng: 5.564514 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 1114,
        },
        {
          NOM: "TRANSPORTS ALAINE",
          PAYS: "FR",
          CP: "71000",
          VILLE: "MACON",
          position: { lat: 46.278088, lng: 4.809205 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 510,
        },
        {
          NOM: "TRANSPORTS ANSQUER MARTIN",
          PAYS: "FR",
          CP: "92230",
          VILLE: "GENNEVILLIERS",
          position: { lat: 48.93591, lng: 2.26957 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 452,
        },
        {
          NOM: "TRANSPORTS ARNAUD",
          PAYS: "FR",
          CP: "47400",
          VILLE: "TONNEINS",
          position: { lat: 44.40553, lng: 0.295366 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 200,
        },
        {
          NOM: "TRANSPORTS BAYON",
          PAYS: "FR",
          CP: "51260",
          VILLE: "ST SATURNIN",
          position: { lat: 48.61046, lng: 3.90327 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 206,
        },
        {
          NOM: "TRANSPORTS BEADE",
          PAYS: "FR",
          CP: "47450",
          VILLE: "COLAYRAC ST CIRQ",
          position: { lat: 44.23375, lng: 0.553449 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 813,
        },
        {
          NOM: "TRANSPORTS BERNARD CLAUDE",
          PAYS: "FR",
          CP: "88290",
          VILLE: "SAULXURES SUR MOSELOTTE",
          position: { lat: 47.94907, lng: 6.76941 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 137,
        },
        {
          NOM: "TRANSPORTS CAUDRON FRERES",
          PAYS: "FR",
          CP: "91220",
          VILLE: "BRETIGNY SUR ORGE",
          position: { lat: 48.592202, lng: 2.301407 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1334,
        },
        {
          NOM: "TRANSPORTS CONTIGNON",
          PAYS: "FR",
          CP: "52100",
          VILLE: "ST DIZIER",
          position: { lat: 48.643258, lng: 4.939604 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 264,
        },
        {
          NOM: "TRANSPORTS DUDOUIT CYRILLE",
          PAYS: "FR",
          CP: "50300",
          VILLE: "LE VAL ST PERE",
          position: { lat: 48.64313, lng: -1.35418 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "TRANSPORTS ETCHETO",
          PAYS: "FR",
          CP: "65350",
          VILLE: "POUYASTRUC",
          position: { lat: 43.27294, lng: 0.17485 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1041,
        },
        {
          NOM: "TRANSPORTS FABRICE LACHARTRE - TFL",
          PAYS: "FR",
          CP: "13127",
          VILLE: "VITROLLES",
          position: { lat: 43.438789, lng: 5.245 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 673,
        },
        {
          NOM: "TRANSPORTS FONTENAY",
          PAYS: "FR",
          CP: "12800",
          VILLE: "NAUCELLE",
          position: { lat: 44.185714, lng: 2.361367 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 1007,
        },
        {
          NOM: "TRANSPORTS FRANCILIEN",
          PAYS: "FR",
          CP: "77100",
          VILLE: "MEAUX",
          position: { lat: 48.96335, lng: 2.9186 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 918,
        },
        {
          NOM: "TRANSPORTS GALTIER",
          PAYS: "FR",
          CP: "12250",
          VILLE: "ROQUEFORT SUR SOULZON",
          position: { lat: 43.977045, lng: 2.951029 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "TRANSPORTS GHILARDI",
          PAYS: "FR",
          CP: "55120",
          VILLE: "CLERMONT EN ARGONNE",
          position: { lat: 49.10116, lng: 5.0959 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 837,
        },
        {
          NOM: "TRANSPORTS INDUSTRIELS CAILLE",
          PAYS: "FR",
          CP: "02000",
          VILLE: "LAON",
          position: { lat: 49.57285, lng: 3.646722 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 44,
        },
        {
          NOM: "TRANSPORTS JUNG",
          PAYS: "FR",
          CP: "57670",
          VILLE: "BENESTROFF",
          position: { lat: 48.90765, lng: 6.75109 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 106,
        },
        {
          NOM: "TRANSPORTS LMJ",
          PAYS: "FR",
          CP: "13127",
          VILLE: "VITROLLES",
          position: { lat: 43.4784, lng: 5.22918 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1061,
        },
        {
          NOM: "TRANSPORTS LOMBART JA",
          PAYS: "FR",
          CP: "62134",
          VILLE: "ANVIN",
          position: { lat: 50.44806, lng: 2.24591 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 683,
        },
        {
          NOM: "TRANSPORTS MALAURIE SARL",
          PAYS: "FR",
          CP: "24480",
          VILLE: "LE BUISSON DE CADOUIN",
          position: { lat: 44.849671, lng: 0.904468 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 424,
        },
        {
          NOM: "TRANSPORTS MESGUEN 35",
          PAYS: "FR",
          CP: "35137",
          VILLE: "PLEUMELEUC",
          position: { lat: 48.177948, lng: -1.927575 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 40,
        },
        {
          NOM: "TRANSPORTS P.FATTON",
          PAYS: "FR",
          CP: "13127",
          VILLE: "VITROLLES",
          position: { lat: 43.43, lng: 5.24913 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 997,
        },
        {
          NOM: "TRANSPORTS PARET",
          PAYS: "FR",
          CP: "89110",
          VILLE: "ST MAURICE THIZOUAILLE",
          position: { lat: 47.835307, lng: 3.369171 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 523,
        },
        {
          NOM: "TRANSPORTS PERRIER",
          PAYS: "FR",
          CP: "54300",
          VILLE: "LUNEVILLE",
          position: { lat: 48.58386, lng: 6.52427 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 613,
        },
        {
          NOM: "TRANSPORTS RAPITEAU ET SES FILS",
          PAYS: "FR",
          CP: "13690",
          VILLE: "GRAVESON",
          position: { lat: 43.885382, lng: 4.781451 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 850,
        },
        {
          NOM: "TRANSPORTS RAVASSO ET FILS",
          PAYS: "FR",
          CP: "83340",
          VILLE: "LE LUC",
          position: { lat: 43.37245, lng: 6.32537 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "TRANSPORTS SICRE",
          PAYS: "FR",
          CP: "65300",
          VILLE: "LANNEMEZAN",
          position: { lat: 43.09929, lng: 0.38258 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 1712,
        },
        {
          NOM: "TRANSPORTS SOULET ET FILS",
          PAYS: "FR",
          CP: "11400",
          VILLE: "VILLENEUVE LA COMPTAL",
          position: { lat: 43.29253, lng: 1.92502 },
          DEPOSE: "N",
          REPRISE: "N",
          EPAL_TV: 0,
        },
        {
          NOM: "TRANSPORTS THIERRY FOREAU",
          PAYS: "FR",
          CP: "84210",
          VILLE: "ALTHEN DES PALUDS",
          position: { lat: 44.00741, lng: 4.95656 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 896,
        },
        {
          NOM: "TRANSVERN SARL",
          PAYS: "FR",
          CP: "24750",
          VILLE: "BOULAZAC",
          position: { lat: 45.185956, lng: 0.778823 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 9,
        },
        {
          NOM: "TSDD TRANSPORTS STOCKAGE DISTRIBUTI",
          PAYS: "FR",
          CP: "29270",
          VILLE: "CARHAIX PLOUGUER",
          position: { lat: 48.28423, lng: -3.55961 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 35,
        },
        {
          NOM: "TSE FRANCE 31",
          PAYS: "FR",
          CP: "31620",
          VILLE: "CASTELNAU D ESTRETEFONDS",
          position: { lat: 43.77227, lng: 1.36397 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 559,
        },
        {
          NOM: "TSE FRANCE LOGISTIQUE",
          PAYS: "FR",
          CP: "69740",
          VILLE: "GENAS",
          position: { lat: 45.720048, lng: 4.985227 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 1061,
        },
        {
          NOM: "TTB TRANSPORTS 61",
          PAYS: "FR",
          CP: "61250",
          VILLE: "CONDE SUR SARTHE",
          position: { lat: 48.438298, lng: 0.040448 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 79,
        },
        {
          NOM: "TTM NORD",
          PAYS: "FR",
          CP: "62220",
          VILLE: "CARVIN",
          position: { lat: 50.475387, lng: 2.971267 },
          DEPOSE: "N",
          REPRISE: "N",
          EPAL_TV: 0,
        },
        {
          NOM: "ULEX PGMBH",
          PAYS: "BE",
          CP: "4700",
          VILLE: "EUPEN",
          position: { lat: 50.64144, lng: 6.0091 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 159,
        },
        {
          NOM: "UNM BLANCHET",
          PAYS: "FR",
          CP: "10400",
          VILLE: "NOGENT SUR SEINE",
          position: { lat: 48.494746, lng: 3.480498 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 480,
        },
        {
          NOM: "VAN MIEGHEM SAINTES",
          PAYS: "BE",
          CP: "1480",
          VILLE: "SAINTES",
          position: { lat: 50.709331, lng: 4.171501 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 22,
        },
        {
          NOM: "VECANORD TC59",
          PAYS: "FR",
          CP: "59950",
          VILLE: "AUBY",
          position: { lat: 50.409201, lng: 3.062434 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 781,
        },
        {
          NOM: "VECATEL",
          PAYS: "FR",
          CP: "25700",
          VILLE: "VALENTIGNEY",
          position: { lat: 47.482385, lng: 6.833747 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 63,
        },
        {
          NOM: "VERDIER",
          PAYS: "FR",
          CP: "63114",
          VILLE: "COUDES",
          position: { lat: 45.610021, lng: 3.220156 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 858,
        },
        {
          NOM: "VERLHAC ET FILS TRANSPORTS",
          PAYS: "FR",
          CP: "46090",
          VILLE: "LE MONTAT",
          position: { lat: 44.35602, lng: 1.4642 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 548,
        },
        {
          NOM: "VERVERKEN TRANSPORTS",
          PAYS: "FR",
          CP: "27390",
          VILLE: "MONTREUIL L ARGILLE",
          position: { lat: 48.944403, lng: 0.474297 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 0,
        },
        {
          NOM: "VIAL TRANSPORTS",
          PAYS: "FR",
          CP: "42160",
          VILLE: "BONSON",
          position: { lat: 45.519665, lng: 4.20597 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 362,
        },
        {
          NOM: "VIATIM TRANSPORTS",
          PAYS: "FR",
          CP: "21550",
          VILLE: "LADOIX SERRIGNY",
          position: { lat: 47.06593, lng: 4.87023 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 610,
        },
        {
          NOM: "VIGNERON",
          PAYS: "FR",
          CP: "54710",
          VILLE: "LUDRES",
          position: { lat: 48.616028, lng: 6.194748 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 970,
        },
        {
          NOM: "VILLEPARISIS VALORISATION BOIS",
          PAYS: "FR",
          CP: "77270",
          VILLE: "VILLEPARISIS",
          position: { lat: 48.933843, lng: 2.622034 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 95,
        },
        {
          NOM: "VINGEANNE",
          PAYS: "FR",
          CP: "52160",
          VILLE: "PERROGNEY LES FONTAINES",
          position: { lat: 47.79884, lng: 5.2272 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 478,
        },
        {
          NOM: "VINGEANNE 21",
          PAYS: "FR",
          CP: "21800",
          VILLE: "CHEVIGNY ST SAUVEUR",
          position: { lat: 47.300486, lng: 5.145202 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 1826,
        },
        {
          NOM: "VITADIS",
          PAYS: "FR",
          CP: "80420",
          VILLE: "VILLE LE MARCLET",
          position: { lat: 50.0248, lng: 2.07326 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 774,
        },
        {
          NOM: "VOS CARGO LOGISTICS",
          PAYS: "BE",
          CP: "8740",
          VILLE: "PITTEM",
          position: { lat: 50.991086, lng: 3.243894 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 292,
        },
        {
          NOM: "ZANON TRANSPORTS",
          PAYS: "FR",
          CP: "38360",
          VILLE: "SASSENAGE",
          position: { lat: 45.202121, lng: 5.663426 },
          DEPOSE: "O",
          REPRISE: "O",
          EPAL_TV: 635,
        },
        {
          NOM: "ZANUT TRANSPORTS",
          PAYS: "FR",
          CP: "33130",
          VILLE: "BEGLES",
          position: { lat: 44.811534, lng: -0.542943 },
          DEPOSE: "N",
          REPRISE: "O",
          EPAL_TV: 0,
        },
      ],
    };
  },
  watch: {
    // // the bounds of the map are changed to fit the points
    // // MapA
    // markersA(m) {
    //   if (m.length > 2) this.updateBounds(m, "mapA");
    // },
    // // MapB
    // markersB(m) {
    //   if (m.length > 2) this.updateBounds(m, "mapB");
    // },
    // palBank: {
    //   //deep: true, // Detect nested value changes inside Objects
    //   immediate: true, // Initiate at first load, trigger the callback immediately with the current value of the expression
    //   handler: function (m) {
    //     if (m.length > 2) this.updateBounds(m, "mapB");
    //   },
    // },
  },
  beforeMount() {
    // Add body class
    this.$store.dispatch(ADD_BODY_CLASSNAME, "fitted-content");
  },
  destroyed() {
    // Remove body class
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "fitted-content");
  },
  mounted() {
    this.$refs.mapB.$mapPromise.then(() => {
      // add ref="map" to the <GmapMap ref="map"> like so
      this.googleMapsInitialized = true; // googleMapsInitalized needs to be in data object to be reactive
      this.mapOptions.zoomControlOptions.position = google.maps.ControlPosition.TOP_LEFT;
      console.log("[MAPS] googleMapsInitialized::", google.maps, window.google.maps, this.googleMapsInitialized);
      this.updateBounds();
    });
  },
  computed: {
    //google: this.VueGoogleMaps,
  },
  methods: {
    // GoogleMap

    // Render custom icon
    markerIcon(label) {
      if (this.googleMapsInitialized !== true) return;

      const svg = window.btoa(`
  <svg fill="#25ae99" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    <circle cx="120" cy="120" opacity=".9" r="70" />
  </svg>`);
      //    <text x="120" y="120" fill="#00FF00" font-size="100">#${label}</text>

      return {
        url: `data:image/svg+xml;base64,${svg}`,
        scaledSize: new window.google.maps.Size(55, 55),
        anchor: new window.google.maps.Point(16, 16),
      };
    },

    markerLabel(label) {
      return {
        text: String(label), // + " (" + String(count) + ")"
        color: "rgba(255,255,255,0.9)",
        fontSize: "10px",
        fontFamily: "Poppins, Helvetica, sans-serif",
        fontWeight: "bold",
      };
    },

    // Render custum cluster
    // clusterOptions() {
    //   return {
    //     renderer: {
    //       render: ({ count, position }) =>
    //         new google.maps.Marker({
    //           label: { text: "#" + String(count), color: "white", fontSize: "10px" },
    //           //icon: VesselCluster,
    //           position,
    //           // adjust zIndex to be above other markers
    //           zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
    //         }),
    //     },
    //   };
    // },

    clusterOptions() {
      return {
        //algorithm: new GridAlgorithm({}),
        renderer: {
          render: ({ count, position, markers }, stats) => {
            console.log(markers);
            let labels = 0;
            for (let m of markers) {
              if (typeof m.label === "object") labels += Number(m.label.text);
            }

            // change color if this cluster has more markers than the mean cluster
            const color = count > Math.max(10, stats.clusters.markers.mean) ? "#ff0000" : "#0000ff";
            //const color = this.palette(count / stats.clusters.markers.max);

            // create svg url with fill color
            const svg = window.btoa(`
  <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    <circle cx="120" cy="120" opacity=".6" r="70" />
    <circle cx="120" cy="120" opacity=".3" r="90" />
    <circle cx="120" cy="120" opacity=".2" r="110" />
  </svg>`);
            // create marker using svg icon
            return new window.google.maps.Marker({
              position,
              icon: {
                url: `data:image/svg+xml;base64,${svg}`,
                scaledSize: new window.google.maps.Size(55, 55),
              },
              label: {
                text: String(labels !== 0 ? labels : count), // + " (" + String(count) + ")"
                color: "rgba(255,255,255,0.9)",
                fontSize: "10px",
                fontFamily: "Poppins, Helvetica, sans-serif",
                fontWeight: "bold",
              },
              title: `Cluster of ${count} markers`,
              // adjust zIndex to be above other markers
              zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
            });
          },
        },
      };
    },
    // Sync two maps
    zoomMap(zoom, ref) {
      console.log("[MAPS] zoomMap::", zoom, ref, this.$refs[ref].$mapObject.getZoom());
      if (zoom !== this.$refs[ref].$mapObject.getZoom()) this.$refs[ref].$mapObject.setZoom(zoom);
    },
    centerMap(bounds, ref) {
      console.log(
        "[MAPS] centerMap::",
        bounds,
        ref,
        this.dragging[ref],
        Object.values(this.dragging).some((x) => x === true)
      );
      if (this.dragging[ref] === false && Object.values(this.dragging).some((x) => x === true)) this.$refs[ref].$mapObject.setCenter(bounds);
    },
    dragMap(e, ref) {
      console.log("[MAPS] dragMap::", e, ref);
      // On drag
      this.dragging[ref] = e === "start" ? true : false;
      // On click
      if (e === "click") {
        this.dragging[ref] = true;
        setTimeout(() => {
          console.log("[MAPS] STOP dragMap::", e, ref);
          this.dragging[ref] = false;
        }, 200);
      }
    },

    //
    updateCoordinates(location, ref) {
      this["markers" + ref] = [
        {
          position: { lat: location.lat(), lng: location.lng() },
        },
      ];
      console.log(ref, this["markers" + ref]);
    },
    updateBounds(m = [...this.markersA, ...this.markersB, ...this.palBank], ref) {
      console.log("[MAPS] updateBounds::", m.length, ref, this.googleMapsInitialized);

      const bounds = new window.google.maps.LatLngBounds();
      for (let m of m) {
        bounds.extend(m.position);
      }
      if (ref) this.$refs[ref].fitBounds(bounds);
      else {
        this.$refs.mapA.fitBounds(bounds);
        this.$refs.mapB.fitBounds(bounds);
      }
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    centerPlace() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.initialCenter = marker;
        this.currentPlace = null;
      }
    },
    geolocationMap() {
      // Try HTML5 geolocation.
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };

            window.swalToast.fire({
              title: this.$gettext("Find me !"),
              html: this.$gettext("We succesfully found your current position !"),
              icon: "success",
              //width: "40rem",
            });

            this.$refs.mapA.$mapObject.setCenter(pos);
            this.$refs.mapB.$mapObject.setCenter(pos);
            this.$refs.mapA.$mapObject.setZoom(19);
            this.$refs.mapB.$mapObject.setZoom(19);
          },
          (err) => {
            window.swalToast.fire({
              title: this.$gettext("Find me !"),
              html: this.$gettext("Error trying to get your current position..."),
              icon: "error",
              //width: "40rem",
              footer: `${err.code} : ${err.message}`,
            });
          }
        );
      } else {
        // Browser doesn't support Geolocation
        window.swalToast.fire({
          title: this.$gettext("Find me !"),
          html: this.$gettext("Your browser doesn't support Geolocation..."),
          icon: "info",
          //width: "40rem",
        });
      }
    },
  },
};
</script>
